const url = {
  base: process.env.REACT_APP_API,
  account: "account",
  analytics: "analytics",
  bills: "bills",
  cashback: "cashback",
  cashpoint: "cashpoint",
  media: "media",
  merchant: "merchant",
  loan: "loan",
  payout: "payout",
  terminal: "terminal",
  transaction: "transaction",
  ticket: "ticket",
  wallet: "wallet",
};

export default url;
