import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBankList,
  fetchTransactionStatus,
  fetchTransactionTypes,
  fetchUssdSupportedBank,
} from "../../api/transactionsApi";

const initialState = {
  transactionTypesList: [],
  transactionStatusList: [],
  bankUssd: [],
  listOfBanks: [],
  isFetching: false,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTransactionTypes.fulfilled, (state, action) => {
      state.transactionTypesList = action.payload;
    });
    builder.addCase(fetchTransactionStatus.fulfilled, (state, action) => {
      state.transactionStatusList = action.payload;
    });
    builder.addCase(fetchUssdSupportedBank.fulfilled, (state, action) => {
      state.bankUssd = action.payload;
    });
    builder.addCase(fetchBankList.fulfilled, (state, { payload }) => {
      state.listOfBanks = payload;
      state.isFetching = false;
    });
    builder.addCase(fetchBankList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchBankList.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export default transactionSlice.reducer;
