import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export const setAppManifest = (manifest) => {
  const logoUrl = manifest.icons[0]?.src;
  const appTitle = manifest.name;
  // manifest.start_url = window.location.origin;
  const newManifest = { ...manifest, start_url: window.location.origin };
  const stringManifest = JSON.stringify(newManifest);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  const defaultManifest = "%PUBLIC_URL%/manifest.json";
  const defaultLogo = "%PUBLIC_URL%/default.png";

  document.title = appTitle || "Grupp Businesss App";
  document
    .querySelector("#app-manifest-placeholder")
    .setAttribute("href", manifestURL || defaultManifest);
  document
    .querySelector("#app-favicon")
    .setAttribute("href", logoUrl || defaultLogo);
  document
    .querySelector("#app-apple-favicon")
    .setAttribute("href", logoUrl || defaultLogo);

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register();
};
