import React from "react";
import Icon from "@ant-design/icons";

const MenuSvg = () => {
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 18 16"
      xmlns="http://www.w3.org/2000/svg"
      data-icon="setting"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.600098 2.00078C0.600098 1.68252 0.726526 1.3773 0.951569 1.15225C1.17661 0.92721 1.48184 0.800781 1.8001 0.800781H16.2001C16.5184 0.800781 16.8236 0.92721 17.0486 1.15225C17.2737 1.3773 17.4001 1.68252 17.4001 2.00078C17.4001 2.31904 17.2737 2.62427 17.0486 2.84931C16.8236 3.07435 16.5184 3.20078 16.2001 3.20078H1.8001C1.48184 3.20078 1.17661 3.07435 0.951569 2.84931C0.726526 2.62427 0.600098 2.31904 0.600098 2.00078Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.600098 8.00078C0.600098 7.68252 0.726526 7.3773 0.951569 7.15225C1.17661 6.92721 1.48184 6.80078 1.8001 6.80078H16.2001C16.5184 6.80078 16.8236 6.92721 17.0486 7.15225C17.2737 7.3773 17.4001 7.68252 17.4001 8.00078C17.4001 8.31904 17.2737 8.62427 17.0486 8.84931C16.8236 9.07435 16.5184 9.20078 16.2001 9.20078H1.8001C1.48184 9.20078 1.17661 9.07435 0.951569 8.84931C0.726526 8.62427 0.600098 8.31904 0.600098 8.00078Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.600098 14.0008C0.600098 13.6825 0.726526 13.3773 0.951569 13.1523C1.17661 12.9272 1.48184 12.8008 1.8001 12.8008H9.0001C9.31836 12.8008 9.62358 12.9272 9.84863 13.1523C10.0737 13.3773 10.2001 13.6825 10.2001 14.0008C10.2001 14.319 10.0737 14.6243 9.84863 14.8493C9.62358 15.0744 9.31836 15.2008 9.0001 15.2008H1.8001C1.48184 15.2008 1.17661 15.0744 0.951569 14.8493C0.726526 14.6243 0.600098 14.319 0.600098 14.0008Z"
      />
    </svg>
  );
};

const MenuIcon = (props) => <Icon component={MenuSvg} {...props} />;

export default MenuIcon;
