import React from "react";
import Icon from "@ant-design/icons";

const CashpointSvg = () => {
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-icon="setting"
    >
      <g clipPath="url(#clip0)">
        <path d="M12 9.70312C14.2487 9.70312 16.0781 7.87369 16.0781 5.625C16.0781 3.37631 14.2487 1.54688 12 1.54688C9.75131 1.54688 7.92188 3.37631 7.92188 5.625C7.92188 7.87369 9.75131 9.70312 12 9.70312ZM12 2.95312C13.4733 2.95312 14.6719 4.15172 14.6719 5.625C14.6719 7.09828 13.4733 8.29688 12 8.29688C10.5267 8.29688 9.32812 7.09828 9.32812 5.625C9.32812 4.15172 10.5267 2.95312 12 2.95312Z" />
        <path d="M20.25 9.70312C21.6716 9.70312 22.8281 8.54658 22.8281 7.125C22.8281 5.70342 21.6716 4.54688 20.25 4.54688C18.8284 4.54688 17.6719 5.70342 17.6719 7.125C17.6719 8.54658 18.8284 9.70312 20.25 9.70312ZM20.25 5.95312C20.8962 5.95312 21.4219 6.47883 21.4219 7.125C21.4219 7.77117 20.8962 8.29688 20.25 8.29688C19.6038 8.29688 19.0781 7.77117 19.0781 7.125C19.0781 6.47883 19.6038 5.95312 20.25 5.95312Z" />
        <path d="M20.8172 11.2969H19.7297C18.4482 11.2969 17.3419 12.054 16.8378 13.1415C15.8 12.0085 14.3092 11.2969 12.6553 11.2969H11.3448C9.69084 11.2969 8.20008 12.0085 7.16222 13.1415C6.65808 12.054 5.55183 11.2969 4.27031 11.2969H3.18281C1.42781 11.2969 0 12.7161 0 14.4605V19.5797C0 20.337 0.618469 20.9531 1.37869 20.9531H5.67839C5.75048 21.7922 6.45605 22.4531 7.31353 22.4531H16.6865C17.544 22.4531 18.2495 21.7922 18.3217 20.9531H22.5722C23.3595 20.9531 24 20.3152 24 19.5309V14.4605C24 12.7161 22.5722 11.2969 20.8172 11.2969ZM1.40625 14.4605C1.40625 13.4915 2.20322 12.7031 3.18281 12.7031H4.27031C5.24991 12.7031 6.04688 13.4915 6.04688 14.4605V14.9419C5.57405 16.173 5.67188 16.9109 5.67188 19.5469H1.40625V14.4605ZM16.9219 20.8115C16.9219 20.9413 16.8163 21.0469 16.6865 21.0469H7.31348C7.18369 21.0469 7.07812 20.9413 7.07812 20.8115V16.9698C7.07812 14.6171 8.99212 12.7031 11.3447 12.7031H12.6552C15.0079 12.7031 16.9219 14.6171 16.9219 16.9697V20.8115ZM22.5938 19.5309C22.5938 19.5552 22.8559 19.5469 18.3281 19.5469C18.3281 16.8914 18.4252 16.171 17.9531 14.9419V14.4605C17.9531 13.4915 18.7501 12.7031 19.7297 12.7031H20.8172C21.7968 12.7031 22.5938 13.4915 22.5938 14.4605V19.5309Z" />
        <path d="M3.75 9.70312C5.17158 9.70312 6.32812 8.54658 6.32812 7.125C6.32812 5.70342 5.17158 4.54688 3.75 4.54688C2.32842 4.54688 1.17188 5.70342 1.17188 7.125C1.17188 8.54658 2.32842 9.70312 3.75 9.70312ZM3.75 5.95312C4.39617 5.95312 4.92188 6.47883 4.92188 7.125C4.92188 7.77117 4.39617 8.29688 3.75 8.29688C3.10383 8.29688 2.57812 7.77117 2.57812 7.125C2.57812 6.47883 3.10383 5.95312 3.75 5.95312Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CashpointIcon = () => <Icon component={CashpointSvg} />;

export default CashpointIcon;
