import { createSlice } from "@reduxjs/toolkit";
import { fetchWalletBalance } from "../../api/dashboardApi";
import { WalletCurrency } from "../../utils/helper";

const initialState = {
  account: {},
  isMobile: false,
  height: null,
  balance: {
    balance: 0,
    bookBalance: 0,
  },
  showWallet: false,
  currency: WalletCurrency.NAIRA,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    showWalletDrawer: (state) => {
      state.showWallet = true;
    },
    closeWalletDrawer: (state) => {
      state.showWallet = false;
    },
    handleViewPoint: (state, { payload }) => {
      state.isMobile = Boolean(payload.width < 640);
      state.height = payload.height;
    },
    checkCurrency: (state, { payload }) => {
      state.currency = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWalletBalance.fulfilled, (state, action) => {
      state.balance = action.payload;
    });
    builder.addCase(fetchWalletBalance.rejected, (state) => {
      state.balance = {
        balance: 0,
        bookBalance: 0,
        totalCredit: 0,
        totalDebit: 0,
      };
    });
  },
});

export const {
  showWalletDrawer,
  closeWalletDrawer,
  handleViewPoint,
  checkCurrency,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
