import React from "react";
import Icon from "@ant-design/icons";

const PersonSvg = () => {
  return (
    <svg
      wwidth="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-icon="setting"
    >
      <path d="M19.5 14.625C19.5 14.0283 19.2629 13.456 18.841 13.034C18.419 12.6121 17.8467 12.375 17.25 12.375H6.75C6.15326 12.375 5.58097 12.6121 5.15901 13.034C4.73705 13.456 4.5 14.0283 4.5 14.625V15.375C4.5 18.3315 7.29 21.375 12 21.375C16.71 21.375 19.5 18.3315 19.5 15.375V14.625ZM6 14.625C6 14.4261 6.07902 14.2353 6.21967 14.0947C6.36032 13.954 6.55109 13.875 6.75 13.875H17.25C17.4489 13.875 17.6397 13.954 17.7803 14.0947C17.921 14.2353 18 14.4261 18 14.625V15.375C18 17.532 15.852 19.875 12 19.875C8.148 19.875 6 17.532 6 15.375V14.625Z" />
      <path d="M16.125 6.75026C16.1251 5.86098 15.8377 4.99549 15.3058 4.28281C14.774 3.57013 14.026 3.04841 13.1735 2.79543C12.3209 2.54245 11.4095 2.57176 10.5749 2.87898C9.7404 3.1862 9.02751 3.75488 8.5425 4.50026H6C5.80109 4.50026 5.61032 4.57928 5.46967 4.71993C5.32902 4.86059 5.25 5.05135 5.25 5.25026V8.25026C5.25 8.27426 5.25 8.29676 5.253 8.31926H5.25V9.75026C5.25 10.347 5.48705 10.9193 5.90901 11.3413C6.33097 11.7632 6.90326 12.0003 7.5 12.0003H8.0805C8.23509 12.0002 8.38715 11.9611 8.52264 11.8867C8.65813 11.8122 8.77266 11.7048 8.85566 11.5744C8.93865 11.444 8.98742 11.2948 8.99747 11.1405C9.00752 10.9863 8.97851 10.832 8.91313 10.6919C8.84775 10.5518 8.74811 10.4305 8.62342 10.3391C8.49873 10.2477 8.35301 10.1893 8.19975 10.1691C8.04648 10.149 7.89061 10.1678 7.74654 10.2238C7.60247 10.2799 7.47486 10.3713 7.3755 10.4898C7.20065 10.4603 7.04188 10.3699 6.92737 10.2345C6.81286 10.0991 6.75002 9.92757 6.75 9.75026V9.00026H7.125C7.5705 9.00026 7.986 8.86976 8.3355 8.64626C8.76006 9.46772 9.44831 10.1227 10.2898 10.5061C11.1312 10.8894 12.0771 10.979 12.9756 10.7603C13.874 10.5416 14.6729 10.0274 15.2441 9.30017C15.8152 8.57297 16.1255 7.67495 16.125 6.75026ZM9.375 6.75026C9.375 6.05407 9.65156 5.38639 10.1438 4.89411C10.6361 4.40182 11.3038 4.12526 12 4.12526C12.6962 4.12526 13.3639 4.40182 13.8562 4.89411C14.3484 5.38639 14.625 6.05407 14.625 6.75026C14.625 7.44646 14.3484 8.11414 13.8562 8.60642C13.3639 9.0987 12.6962 9.37526 12 9.37526C11.3038 9.37526 10.6361 9.0987 10.1438 8.60642C9.65156 8.11414 9.375 7.44646 9.375 6.75026ZM7.875 6.75026C7.875 6.94918 7.79598 7.13994 7.65533 7.28059C7.51468 7.42125 7.32391 7.50026 7.125 7.50026H6.75V6.00026H7.875V6.75026Z" />
    </svg>
  );
};

const Person = (props) => <Icon component={PersonSvg} {...props} />;

export default Person;
