import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    pushNotificationCount: (state, { payload }) => {
      state.count = payload.count;
    },
  },
});

export const { pushNotificationCount } = notificationSlice.actions;
export default notificationSlice.reducer;
