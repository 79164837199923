import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  metrics: [],
  showCongratsModal: false,
  commission: {},
};

const commissionSlice = createSlice({
  name: "commission",
  initialState,
  reducers: {
    setMetrics: (state, action) => {
      let showMessage = false;
      const metricsArr = action.payload;
      const isProgressComplete = metricsArr.find(
        (item) => item.progress === 100
      );
      if (isProgressComplete) {
        showMessage = true;
      }
      state.metrics = metricsArr;
      state.showCongratsModal = showMessage;
      state.commission = isProgressComplete;
    },
    closeCongratsModal: (state, action) => {
      state.showCongratsModal = false;
    },
  },
});

export const { setMetrics, closeCongratsModal } = commissionSlice.actions;

export default commissionSlice.reducer;
