import { useNavigate } from "react-router-dom";
import ModalShell from "../../layout/ModalShell";
import AuthButton from "../Button/AuthButton";

const FailedSvg = (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="28.5" cy="28" r="28" fill="#FADCDC" />
    <path
      d="M22.55 34.45C22.825 34.725 23.175 34.8625 23.6 34.8625C24.025 34.8625 24.375 34.725 24.65 34.45L29 30.1L33.3875 34.4875C33.6625 34.7625 34.0065 34.8935 34.4195 34.8805C34.8315 34.8685 35.175 34.725 35.45 34.45C35.725 34.175 35.8625 33.825 35.8625 33.4C35.8625 32.975 35.725 32.625 35.45 32.35L31.1 28L35.4875 23.6125C35.7625 23.3375 35.8935 22.9935 35.8805 22.5805C35.8685 22.1685 35.725 21.825 35.45 21.55C35.175 21.275 34.825 21.1375 34.4 21.1375C33.975 21.1375 33.625 21.275 33.35 21.55L29 25.9L24.6125 21.5125C24.3375 21.2375 23.994 21.106 23.582 21.118C23.169 21.131 22.825 21.275 22.55 21.55C22.275 21.825 22.1375 22.175 22.1375 22.6C22.1375 23.025 22.275 23.375 22.55 23.65L26.9 28L22.5125 32.3875C22.2375 32.6625 22.1065 33.006 22.1195 33.418C22.1315 33.831 22.275 34.175 22.55 34.45ZM29 43C26.925 43 24.975 42.606 23.15 41.818C21.325 41.031 19.7375 39.9625 18.3875 38.6125C17.0375 37.2625 15.969 35.675 15.182 33.85C14.394 32.025 14 30.075 14 28C14 25.925 14.394 23.975 15.182 22.15C15.969 20.325 17.0375 18.7375 18.3875 17.3875C19.7375 16.0375 21.325 14.9685 23.15 14.1805C24.975 13.3935 26.925 13 29 13C31.075 13 33.025 13.3935 34.85 14.1805C36.675 14.9685 38.2625 16.0375 39.6125 17.3875C40.9625 18.7375 42.031 20.325 42.818 22.15C43.606 23.975 44 25.925 44 28C44 30.075 43.606 32.025 42.818 33.85C42.031 35.675 40.9625 37.2625 39.6125 38.6125C38.2625 39.9625 36.675 41.031 34.85 41.818C33.025 42.606 31.075 43 29 43Z"
      fill="#FF1818"
    />
  </svg>
);

const KycInformationModal = ({ open, close, reason }) => {
  const navigate = useNavigate();
  return (
    <ModalShell
      show={open}
      closable={false}
      cancel={close}
      width={350}
      className="warning rounded-lg filter-modal"
      maskStyle={{ background: "#00000073", backdropFilter: "blur(2px)" }}
    >
      <div className="flex max-w-sm m-auto flex-col items-center gap-5">
        {FailedSvg}
        <h2 className="text-[18px] text-center font-semibold text-[#021230] mt-2">
          Kyc Declined
        </h2>

        <div className="flex flex-col items-center text-center gap-1 text-[#8291AB] text-sm ">
          <p>
            Your kyc has been declined by the banker due to the following
            reasons
          </p>
          <p className="text-lg">
            <strong>{reason}</strong>
          </p>
        </div>
        <div className="w-full mt-5">
          <AuthButton
            className="capitalize w-full"
            onClick={() => navigate("/profile")}
          >
            Edit details
          </AuthButton>
        </div>
      </div>
    </ModalShell>
  );
};

export default KycInformationModal;
