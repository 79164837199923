import React from "react";
import Button from "antd/lib/button";
import { useSelector } from "react-redux";

const AuthButton = ({
  className,
  onClick,
  children,
  loading,
  disabled,
  htmlType,
  bradius = false,
  icon,
}) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <Button
      className={`${className} ${
        bradius ? "" : "grupp-btn"
      } md:h-12 h-10 md:text-sm text-xs p-2 text-white rounded-md border-0 active:bg-[#021230] focus:bg-[#021230] active:text-white focus:text-[#fff] hover:bg-gray-700 ${
        disabled ? "auth-button-disabled" : "bg-[#021230]"
      }`}
      onClick={onClick}
      loading={loading}
      icon={icon}
      disabled={disabled}
      htmlType={htmlType}
      style={{
        color: "white",
        backgroundColor: disabled
          ? user?.color?.secondaryColor
          : user?.color?.primaryColor || "",
      }}
    >
      {children}
    </Button>
  );
};

export default AuthButton;
