import HttpRequest from "../utils/HttpRequests";
import url from "../utils/url";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const Request = new HttpRequest();
const baseUrl = url.account;

const setToken = (token) => {
  return Promise.resolve().then(() =>
    localStorage.setItem("grupp.customer.token", token)
  );
};

export const getManifest = createAsyncThunk(
  "auth/getmanifest",
  async (domain, { rejectWithValue }) => {
    try {
      const manifest = await Request.get(`/${baseUrl}/${domain}/manifest`);
      return manifest;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const loginResponse = await Request.postWithoutHeader(
        `/${baseUrl}/login`,
        null,
        data
      );
      if (loginResponse?.status) {
        localStorage.removeItem("grupp.customer.token");
        await setToken(loginResponse.data.accessToken);
      }
      return loginResponse;
    } catch (err) {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data,
      });
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ data, registrationId }, { rejectWithValue }) => {
    try {
      const newUserResponse = await Request.postWithoutHeader(
        `/${baseUrl}/${registrationId}/register-customer`,
        null,
        data
      );
      return newUserResponse;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const validateCheck = async (option, value) => {
  try {
    const checkResponse = await Request.post(
      `${url.base}/account/${option}/validate`,
      null,
      { value: value }
    );
    return Promise.resolve(checkResponse);
  } catch (err) {
    if (err.response)
      return Promise.reject({ message: err.response.data.message });
    return Promise.reject(err);
  }
};

export const sendOtpToUser = async (data) => {
  try {
    const response = await Request.postWithoutHeader(
      `/${baseUrl}/customer/temp-register`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    if (err.response)
      return Promise.reject({ message: err.response.data.message });
    return Promise.reject({ message: "An error occured, please try again" });
  }
};

export const verifyOtpFromNumber = async (data) => {
  try {
    await Request.postWithoutHeader(`/${baseUrl}/otp-validation`, null, data);
    return Promise.resolve({
      message: "Your phone number has been verified",
    });
  } catch (err) {
    return Promise.reject({
      message: err.response.data.message || "Failed to verify phone number",
    });
  }
};

export const resendOtpVerification = async (registrationId) => {
  try {
    const response = await Request.postWithoutHeader(
      `/${baseUrl}/${registrationId}/resend-otp`
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Failed to verify phone number",
    });
  }
};

export const verifyReferral = async (data) => {
  try {
    const response = await Request.postWithoutHeader(
      `/${baseUrl}/referral`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message:
        err.response.data.message || "An error occured, please try again",
    });
  }
};

export const requestEmailOtpVerification = async () => {
  try {
    const response = await Request.post(`/${baseUrl}/email-check-request`);
    return Promise.resolve(response);
  } catch (err) {
    if (err.response) {
      return Promise.reject({ message: err.response?.data.message });
    }
    return Promise.reject(err);
  }
};

export const sendForgotPasswordRequest = async (data) => {
  try {
    const response = await Request.postWithoutHeader(
      `/${baseUrl}/forgot-password`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const handlePasswordRest = async (data) => {
  try {
    const response = await Request.postWithoutHeader(
      `/${baseUrl}/reset-password/customer`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response.data.message || "Something Went Wrong",
    });
  }
};

export const verifyOtpResetPassword = async (data) => {
  try {
    const response = await Request.postWithoutHeader(
      `/${baseUrl}/validate/customer-reset`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response.data.message || "Something Went Wrong",
    });
  }
};

export const fetchStatesList = async () => {
  try {
    const stateResponse = await Request.get(`/location`);
    return Promise.resolve(stateResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchLgaList = async (state) => {
  try {
    const lgaResponse = await Request.get(`/location/${state}`);
    return Promise.resolve(lgaResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePassword = async (data) => {
  try {
    const response = await Request.put(
      `/${baseUrl}/change-password`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    if (err.response)
      return Promise.reject({ message: err.response.data.message });
    return Promise.reject({ message: "An error occured, please try again" });
  }
};

export const validateBvn = async (data) => {
  try {
    const validationResponse = await Request.put(
      `/${baseUrl}/validate-bvn`,
      null,
      data
    );
    return Promise.resolve(validationResponse.data);
  } catch (err) {
    return Promise.reject({
      message: err.response.data.message || err.message.message,
    });
  }
};

export const confirmPassword = async (data) => {
  try {
    const responseData = await Request.postWithoutHeader(
      `/${baseUrl}/login`,
      null,
      data
    );
    return Promise.resolve(responseData);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data.message || "Something went wrong",
    });
  }
};

export const browserValidation = createAsyncThunk(
  "auth/device",
  async (data, { rejectWithValue }) => {
    try {
      const responseData = await Request.postWithoutHeader(
        `${process.env.REACT_APP_API}/browser/validate`,
        null,
        data
      );
      await setToken(responseData.data.accessToken);
      return responseData;
    } catch (err) {
      return rejectWithValue({
        message: err.response?.data.message || "Something went wrong",
      });
    }
  }
);

export const browserSignInRequest = async (data) => {
  try {
    const browser = await axios.post(
      `${process.env.REACT_APP_API}/browser/request`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "grupp.customer.token"
          )}`,
        },
      }
    );
    return Promise.resolve(browser);
  } catch (err) {
    if (err.response) {
      return Promise.reject({ message: err.response?.data.message });
    }
    return Promise.reject(err);
  }
};

export const handleEmailVerification = async (data) => {
  try {
    const verificationResponse = await Request.postWithoutHeader(
      `/${baseUrl}/verify-email`,
      null,
      data
    );
    return Promise.resolve(verificationResponse);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data.message || "Failed to verify email",
    });
  }
};

export const emailVerification = async () => {
  try {
    const verificationResponse = await Request.post(
      `/${baseUrl}/email-check-request`
    );
    return Promise.resolve(verificationResponse);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data.message || err.message,
    });
  }
};

export const verifyRcNumber = async (data) => {
  try {
    const verificationResponse = await Request.post(
      `/${baseUrl}/registration-number`,
      null,
      data
    );
    return Promise.resolve(verificationResponse);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data.message || "Failed to resolve RC Number",
    });
  }
};

export const updateUsername = createAsyncThunk(
  "auth/username",
  async (data, { rejectWithValue }) => {
    try {
      await Request.put(`/${baseUrl}/username`, null, data);
      return data;
    } catch (err) {
      return rejectWithValue({
        message: err.response?.data.message || "Something went wrong",
      });
    }
  }
);

export const retrieveUsername = async (data) => {
  try {
    const response = await Request.postWithoutHeader(
      `/${baseUrl}/forget-username`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message:
        err.response.data.message || "An error occured, please try again",
    });
  }
};

export const getAccountInformation = async (data) => {
  try {
    const response = await Request.post(
      `/${baseUrl}/account-number-info`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      statusCode: err.response.status,
      message: err.response?.data?.message || "Network Error",
    });
  }
};
