import HttpRequest from "../utils/HttpRequests";
import url from "../utils/url";

const Request = new HttpRequest();

export const validateAccount = async (data) => {
  try {
    const response = await Request.post(
      `/${url.transaction}/validate-account`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({ message: error?.response?.data?.message });
  }
};

export const getTransactionServiceFee = async (data) => {
  try {
    const feeResponse = await Request.post(`/service-fee`, null, data);
    return Promise.resolve(feeResponse);
  } catch (error) {
    if (error.response) {
      return Promise.reject({ message: error.response?.data?.message });
    }
    return Promise.reject(error);
  }
};
