import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageAvatar from "../common/Avatars/Avatar";
import NotificationIcon from "../assets/svg/notification.svg";
import logo from "../assets/logo/Grupp-logo.svg";
import { LogoutOutlined } from "@ant-design/icons";
import { handleLogoutModal } from "../app/features/setttingSlice";

const { Header } = Layout;

export default function Navbar() {
  const count = useSelector((s) => s.notification.count);
  const { user, appLogoUrl, appName } = useSelector((s) => s.auth);
  const dispatch = useDispatch();

  function showModal() {
    dispatch(handleLogoutModal(true));
  }
  return (
    <Header
      className="bg-white bg-white md:border-b border-gray-200 border-opacity-70 border-solid 
      flex items-center justify-between mk-nav md:px-[30px] px-5 relative"
    >
      <div className="h-[75%] py-1 xl:hidden block absolute left-1/2 transform -translate-x-1/2">
        <img className="max-h-full" src={appLogoUrl || logo} alt="logo" />
      </div>
      <p className="xl:ml-0 text-[#535353] text-lg font-bold md:block hidden">
        {appName || ``}
      </p>

      <div className="flex gap-3 items-center ml-auto">
        <Link
          to="/notifications"
          className="relative rounded-full w-8 h-8 flex items-center justify-center"
        >
          <img className="w-4" src={NotificationIcon} alt="notfications" />

          {count > 0 && (
            <span className="rounded-full text-[9px] px-1 bg-red-500 text-white text-center leading-normal absolute top-0 right-0">
              {count}
            </span>
          )}
        </Link>

        <div className="md:flex gap-3 hidden items-center">
          <ImageAvatar size={30} profileImageId={user.profileImageId || ""} />
          <div>
            <p className="text-base font-semibold mb-0 leading-none capitalize">
              {user.firstName} {user.lastName}
            </p>
          </div>
        </div>

        <LogoutOutlined
          style={{
            fontSize: 22,
          }}
          onClick={showModal}
        />
      </div>
    </Header>
  );
}
