import React from "react";
import preloader from "../../assets/preloader.gif";

const PageFallback = () => {
  return (
    <div className="relative">
      <div className="h-full py-[100px] w-full flex items-center justify-center">
        <img src={preloader} alt="loading-screen" />
      </div>
    </div>
  );
};

export default PageFallback;
