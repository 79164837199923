import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateAccountFCMToken } from "./api/accountApi";

const RAPID_KEY =
  process.env.REACT_APP_VAPID_KEY ||
  "BDTcOdkrNvHSP0iFoAb6SUNq1Mx6egOdZ4FHgPpwZN1fLZ6leetUsBckyDWpCdtam--hbgzCiwvH4e8nzlz-7fc";

const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_FIREBASE_APIKEY ||
    "AIzaSyDRF0aDxKFxYMvcm_RbB5sPVKWNZTU0r7w",
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH || "mikro-4dacd.firebaseapp.com",
  databaseURL:
    process.env.REACT_APP_FIREBASE_DB || "https://mikro-4dacd.firebaseio.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "mikro-4dacd",
  storageBucket:
    process.env.REACT_APP_FIREBASE_BUCKET || "mikro-4dacd.appspot.com",
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_ID || "1078923068703",
  appId:
    process.env.REACT_APP_FIREBASE_APP_ID ||
    "1:1078923068703:web:d43a980ab02adb1a6d9c2a",
  measurementId:
    process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "G-3GWMC1RXK2",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFCMToken = () => {
  return getOrRegisterServiceWorker()
    .then((serviceWorkerRegistration) =>
      getToken(messaging, { vapidKey: RAPID_KEY, serviceWorkerRegistration })
    )
    .then(async (token) => updateAccountFCMToken(token))
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
