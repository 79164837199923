import React from "react";
import Icon from "@ant-design/icons";

const HomeSvg = () => {
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-icon="setting"
    >
      <path d="M11.999 21.75C14.5849 21.75 17.0648 20.7228 18.8933 18.8943C20.7218 17.0658 21.749 14.5859 21.749 12C21.749 9.41414 20.7218 6.93419 18.8933 5.10571C17.0648 3.27723 14.5849 2.25 11.999 2.25C11.8001 2.25 11.6093 2.32902 11.4687 2.46967C11.328 2.61032 11.249 2.80109 11.249 3V5.25C11.249 5.44891 11.328 5.63968 11.4687 5.78033C11.6093 5.92098 11.8001 6 11.999 6C12.1979 6 12.3887 5.92098 12.5294 5.78033C12.67 5.63968 12.749 5.44891 12.749 5.25V3.78367C14.3103 3.9263 15.7985 4.51081 17.0396 5.46877C18.2806 6.42672 19.223 7.71844 19.7563 9.19266C20.2897 10.6669 20.392 12.2626 20.0512 13.7928C19.7104 15.3231 18.9407 16.7245 17.8321 17.8331C16.7236 18.9417 15.3221 19.7114 13.7918 20.0522C12.2616 20.393 10.6659 20.2907 9.19169 19.7573C7.71747 19.2239 6.42574 18.2816 5.46779 17.0405C4.50984 15.7995 3.92532 14.3112 3.7827 12.75H5.24902C5.44794 12.75 5.6387 12.671 5.77935 12.5303C5.92001 12.3897 5.99902 12.1989 5.99902 12C5.99902 11.8011 5.92001 11.6103 5.77935 11.4697C5.6387 11.329 5.44794 11.25 5.24902 11.25H2.99902C2.80011 11.25 2.60935 11.329 2.46869 11.4697C2.32804 11.6103 2.24902 11.8011 2.24902 12C2.25194 14.585 3.28011 17.0632 5.10796 18.8911C6.9358 20.7189 9.41406 21.7471 11.999 21.75Z" />
      <path d="M10.5548 11.6169C10.5195 11.7417 10.5007 11.8707 10.499 12.0004C10.499 12.2971 10.5869 12.5871 10.7518 12.8338C10.9166 13.0805 11.1509 13.2727 11.4249 13.3863C11.699 13.4998 12.0006 13.5295 12.2916 13.4716C12.5826 13.4137 12.8499 13.2709 13.0596 13.0611C13.2694 12.8513 13.4123 12.584 13.4702 12.2931C13.528 12.0021 13.4983 11.7005 13.3848 11.4264C13.2713 11.1523 13.079 10.9181 12.8323 10.7532C12.5857 10.5884 12.2956 10.5004 11.999 10.5004C11.8692 10.5022 11.7403 10.521 11.6154 10.5564L6.15421 5.09518C6.08503 5.02354 6.00227 4.96641 5.91077 4.9271C5.81926 4.88779 5.72085 4.8671 5.62126 4.86624C5.52168 4.86537 5.42292 4.88435 5.33075 4.92206C5.23858 4.95977 5.15484 5.01546 5.08442 5.08588C5.014 5.1563 4.95831 5.24004 4.9206 5.33221C4.88289 5.42438 4.86391 5.52314 4.86477 5.62273C4.86564 5.72231 4.88633 5.82073 4.92564 5.91223C4.96494 6.00374 5.02208 6.08649 5.09371 6.15568L10.5548 11.6169Z" />
      <path d="M15.749 12C15.749 12.1989 15.828 12.3897 15.9687 12.5303C16.1093 12.671 16.3001 12.75 16.499 12.75H17.999C18.1979 12.75 18.3887 12.671 18.5294 12.5303C18.67 12.3897 18.749 12.1989 18.749 12C18.749 11.8011 18.67 11.6103 18.5294 11.4697C18.3887 11.329 18.1979 11.25 17.999 11.25H16.499C16.3001 11.25 16.1093 11.329 15.9687 11.4697C15.828 11.6103 15.749 11.8011 15.749 12Z" />
      <path d="M15.7117 7.22641L14.6506 8.28714C14.5809 8.35677 14.5256 8.43945 14.4879 8.53045C14.4501 8.62145 14.4307 8.71899 14.4307 8.8175C14.4306 8.91601 14.45 9.01356 14.4877 9.10459C14.5253 9.19561 14.5806 9.27833 14.6502 9.34801C14.7198 9.4177 14.8025 9.47298 14.8935 9.51071C14.9845 9.54844 15.082 9.56788 15.1806 9.56792C15.2791 9.56795 15.3766 9.54858 15.4676 9.51091C15.5587 9.47325 15.6414 9.41802 15.7111 9.34839L16.7724 8.28789C16.8421 8.21824 16.8974 8.13554 16.9351 8.04452C16.9729 7.95351 16.9923 7.85595 16.9923 7.75741C16.9924 7.65888 16.973 7.56131 16.9353 7.47026C16.8977 7.37922 16.8424 7.29648 16.7728 7.22678C16.7031 7.15709 16.6204 7.10179 16.5294 7.06405C16.4384 7.02631 16.3408 7.00687 16.2423 7.00684C16.1438 7.0068 16.0462 7.02617 15.9551 7.06385C15.8641 7.10152 15.7814 7.15676 15.7117 7.22641Z" />
    </svg>
  );
};

const Home = (props) => <Icon component={HomeSvg} {...props} />;

export default Home;
