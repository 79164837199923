import { lazy } from "react";

const componentLoader = (link) => lazy(() => import(`../modules/${link}`));

export const protectedRoutes = [
  {
    path: "home",
    Component: componentLoader("Dashboard/Dashboard"),
  },
  {
    path: "success",
    Component: componentLoader("Status/SuccessPage"),
  },
  {
    path: "kyc",
    Component: componentLoader("Status/KYCStatus"),
  },
  {
    path: "transactions",
    Component: componentLoader("Transactions/Transactions"),
  },
  {
    path: "confirm-transaction",
    Component: componentLoader("Bills/ConfirmTransaction"),
  },
  {
    path: "profile",
    Component: componentLoader("Profile/ProfileSetting"),
  },
  {
    path: "profile/view",
    Component: componentLoader("Profile/ProfileType"),
  },
  {
    path: "profile/business",
    Component: componentLoader("Profile/BusinessView"),
  },
  {
    path: "profile/director",
    Component: componentLoader("Profile/DirectorView"),
  },
  {
    path: "profile/reconciliation",
    Component: componentLoader("Profile/Finance"),
  },
  {
    path: "profile/activate-terminal",
    Component: componentLoader("Profile/TerminalActivation"),
  },
  {
    path: "profile/network-status",
    Component: componentLoader("Profile/NetworkManagement"),
  },
  {
    path: "profile/payout",
    Component: componentLoader("Profile/PayoutInfo"),
  },

  {
    path: "profile/change-password",
    Component: componentLoader("Profile/ChangePassword"),
  },
  {
    path: "pay-bills",
    Component: componentLoader("Bills/Bills"),
  },
  {
    path: "pay-bills/airtime",
    Component: componentLoader("Bills/Airtime"),
  },
  {
    path: "pay-bills/data",
    Component: componentLoader("Bills/Data"),
  },
  {
    path: "pay-bills/cable-tv",
    Component: componentLoader("Bills/CableTv"),
  },
  {
    path: "pay-bills/betting",
    Component: componentLoader("Bills/Betting"),
  },
  {
    path: "pay-bills/phcn",
    Component: componentLoader("Bills/Phcn"),
  },
  {
    path: "pay-bills/betting",
    Component: componentLoader("Bills/Betting"),
  },
  {
    path: "transaction-status",
    Component: componentLoader("Status/TransactionStatus"),
  },
  {
    path: "cashpoint",
    Component: componentLoader("Cashpoint/Cashpoint"),
  },
  {
    path: "cashpoint/activate-terminal",
    Component: componentLoader("Profile/TerminalActivation"),
  },
  {
    path: "cashpoint/:id/:terminalId",
    Component: componentLoader("Cashpoint/CashpointTransaction"),
  },
  {
    path: "cashpoint/:terminalId/create-password",
    Component: componentLoader("Cashpoint/TerminalPin"),
  },
  {
    path: "cashpoint-transactions/:terminalId",
    Component: componentLoader("Transactions/Transactions"),
  },
  {
    path: "create-cashpoint",
    Component: componentLoader("Cashpoint/CreateCashpoint"),
  },
  {
    path: "update-cashpoint",
    Component: componentLoader("Cashpoint/UpdateCashpoint"),
  },
  {
    path: "account-opening",
    Component: componentLoader("AccountOpening/AccountOpening"),
  },
  {
    path: "account-opening/new",
    Component: componentLoader("AccountOpening/NewAccount"),
  },
  {
    path: "account-opening/forms",
    Component: componentLoader("AccountOpening/AccountOpeningForms"),
  },
  {
    path: "account-opening/response",
    Component: componentLoader("AccountOpening/AccountList"),
  },
  {
    path: "account-opening/status",
    Component: componentLoader("AccountOpening/AccountStatus"),
  },
  {
    path: "wallet",
    Component: componentLoader("Wallet/Wallet"),
  },
  {
    path: "wallet/transactions",
    Component: componentLoader("Wallet/MainTable"),
  },
  {
    path: "cashpoint-wallet/transactions/:id",
    Component: componentLoader("Wallet/MainTable"),
  },
  {
    path: "commissions",
    Component: componentLoader("Commissions/Commissions"),
  },
  {
    path: "commissions/list",
    Component: componentLoader("Commissions/CommissionsTable"),
  },
  {
    path: "commissions/overview",
    Component: componentLoader("Commissions/Overview"),
  },
  {
    path: "commissions/withdraw",
    Component: componentLoader("Commissions/CommissionWithdrawal"),
  },
  {
    path: "ussd-withdrawal",
    Component: componentLoader("Withdrawal/UssdWithdrawal"),
  },
  {
    path: "notifications",
    Component: componentLoader("Notification/Notification"),
  },
  {
    path: "transfer",
    Component: componentLoader("Transfer/TransferOption"),
  },
  {
    path: "transfer/form",
    Component: componentLoader("Transfer/Transfer"),
  },
  {
    path: "transfer/my-account",
    Component: componentLoader("Transfer/SavedAccount"),
  },
  {
    path: "support",
    Component: componentLoader("Support/Support"),
  },
  {
    path: "tickets",
    Component: componentLoader("Support/Tickets"),
  },
  {
    path: "support/contact",
    Component: componentLoader("Support/Contact"),
  },
  {
    path: "support/tickets/:id",
    Component: componentLoader("Support/TicketId"),
  },
  {
    path: "transaction/:category",
    Component: componentLoader("Support/Issues"),
  },
  {
    path: "transaction/:category/issue",
    Component: componentLoader("Support/SpecifyIssue"),
  },
  {
    path: "loan",
    Component: componentLoader("Loan/Loan"),
  },
  {
    path: "loan/offers",
    Component: componentLoader("Loan/LoanOffers"),
  },
  {
    path: "loan/terms-condition",
    Component: componentLoader("Loan/TermsAndCondition"),
  },
  {
    path: "loan/status",
    Component: componentLoader("Loan/LoanStatusMessage"),
  },
  {
    path: "loan/offers/:id",
    Component: componentLoader("Loan/LoanVendor"),
  },
  {
    path: "loan-status",
    Component: componentLoader("Loan/RunningLoan"),
  },
  {
    path: "settlement",
    Component: componentLoader("Settlement/Settlement"),
  },
  {
    path: "/settlement/:id",
    Component: componentLoader("Settlement/SettlementTransaction"),
  },
  {
    path: "/account-level",
    Component: componentLoader("AccountLevel/AccountLevel"),
  },
  {
    path: "/account-level/form",
    Component: componentLoader("AccountLevel/AccountLevelForm"),
  },
  {
    path: "/payout",
    Component: componentLoader("Payout/Payout"),
  },
];
