import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import DefaultBG from "../assets/auth_bg.png";
import MerchantBG from "../assets/merchant_bg.png";
import Logo from "../assets/logo/Grupp-logo.svg";
import { useSelector } from "react-redux";

const AuthShell = () => {
  const { formInfo, appLogoUrl } = useSelector((state) => state.auth);
  const location = useLocation();

  return (
    <div className="flex xl:flex-row flex-col w-full xl:h-screen min-h-screen">
      <motion.div
        key={location.pathname}
        initial="pageInitial"
        animate="pageAnimate"
        variants={{
          pageInitial: {
            opacity: 0.5,
          },
          pageAnimate: {
            opacity: 1,
          },
        }}
        className={`xl:w-1/2 w-full xl:h-full bg-half relative lg:block hidden`}
      >
        <img
          className="w-full h-full absolute top-0 left-0 z-1"
          src={formInfo?.userType === "MERCHANT" ? MerchantBG : DefaultBG}
          alt="background"
        />
      </motion.div>

      <motion.div
        key={location.pathname / 2}
        initial="pageInitial"
        animate="pageAnimate"
        variants={{
          pageInitial: {
            opacity: 0,
            x: 500,
          },
          pageAnimate: {
            opacity: 1,
            x: 0,
          },
        }}
        className="xl:w-1/2 w-full lg:h-screen h-full relative py-5 md:py-10 md:pb-16 overflow-y-auto"
      >
        <div className="px-2 2xl:w-7/12 xl:w-2/3 md:w-3/4 w-11/12 m-auto">
          <div className="hidden lg:block h-20 w-36 mb-16">
            <img
              className="max-w-full max-h-full"
              src={appLogoUrl || Logo}
              alt="logo"
            />
          </div>
          <Outlet />
        </div>
      </motion.div>
    </div>
  );
};

export default AuthShell;
