import React from "react";
import ModalShell from "../../layout/ModalShell";
import AuthButton from "../Button/AuthButton";

const SuccessIcon = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.5" cy="28" r="28" fill="#DCFAEF" />
      <path
        d="M40.9163 19.125L24.1872 35.8542L16.583 28.25"
        stroke="#039855"
        strokeWidth="3.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SuccessModal = ({ show, onCancel, message, text, button = "Thanks" }) => {
  return (
    <ModalShell
      show={show}
      width={400}
      cancel={onCancel}
      closable={false}
      className="new-modal"
    >
      <div className="flex flex-col justify-center items-center">
        <SuccessIcon />

        <h3 className="text-[20px] text-[#021230] text-center font-semibold my-2">
          {text}
        </h3>

        <p className="text-[#8291AB] text-center mb-10">{message} </p>
        <div className="flex w-full justify-between">
          <AuthButton
            htmlType="submit"
            className="w-full uppercase"
            onClick={onCancel}
          >
            {button}
          </AuthButton>
        </div>
      </div>
    </ModalShell>
  );
};
export default SuccessModal;
