import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payBills: {},
};

const billsSlice = createSlice({
  name: "bills",
  initialState,
  reducers: {
    setBillsTransactionInformation: (state, { payload }) => {
      state.payBills = payload;
    },
  },
});

export const { setBillsTransactionInformation } = billsSlice.actions;
export default billsSlice.reducer;
