import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpRequest from "../utils/HttpRequests";
import url from "../utils/url";

const Request = new HttpRequest();

export const updateUserAccount = async (data) => {
  try {
    const response = await Request.put(`/${url.account}/update`, null, data);
    if (response.status) return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Failed to update profile",
    });
  }
};

export const getCustomerInfo = createAsyncThunk(
  "user/info",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Request.get(`/${url.account}/me`);
      return response;
    } catch (err) {
      return rejectWithValue({
        message: err.response.data.message || "Failed to fetch info",
      });
    }
  }
);

export const getVirtualAccountStatus = createAsyncThunk(
  "user/virtual-info",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Request.get(`/${url.account}/me`);
      return response;
    } catch (err) {
      return rejectWithValue({
        message: err.response.data.message || "Failed to fetch info",
      });
    }
  }
);

export const handleVirtualAccountRequest = createAsyncThunk(
  "request/virtual-account",
  async (data) => {
    try {
      const response = await Request.put(
        `/${url.account}/virtual-account/request`,
        null,
        data
      );
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject({
        message: err.response?.data?.message || "Failed to update profile",
      });
    }
  }
);

export const generateBusinessAccount = createAsyncThunk(
  "business/virtual-account",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Request.put(
        `/${url.account}/update-business`,
        null,
        data
      );
      return {
        ...response.data,
        ...data,
      };
    } catch (err) {
      return rejectWithValue({
        message: err.response.data.message || "Failed to fetch info",
      });
    }
  }
);

export const updateAccountFCMToken = async (token) => {
  try {
    await Request.put(`/account/fcmToken`, null, { fcmToken: token });
    return Promise.resolve(true);
  } catch (err) {
    return Promise.reject({ err: err.response.data.message });
  }
};
