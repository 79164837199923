import { lazy } from "react";

const componentLoader = (link) => lazy(() => import(`../modules/${link}`));

export const authRoutes = [
  {
    path: "login",
    Component: componentLoader("Auth/Login"),
  },
  {
    path: "referral/:code",
    Component: componentLoader("Auth/Referral"),
  },
  {
    path: "username",
    Component: componentLoader("Auth/Username"),
  },
  {
    path: "referral",
    Component: componentLoader("Auth/Referral"),
  },
  {
    path: "account-number",
    Component: componentLoader("Auth/AccountVerification"),
  },
  {
    path: "cac",
    Component: componentLoader("Auth/CacCheck"),
  },
  {
    path: "cac-information",
    Component: componentLoader("Auth/CacInformation"),
  },
  {
    path: "business-type",
    Component: componentLoader("Auth/BusinessType"),
  },
  {
    path: "new-account",
    Component: componentLoader("Auth/NewAccount"),
  },
  {
    path: "business-info",
    Component: componentLoader("Auth/BusinessInformation"),
  },
  {
    path: "director-info",
    Component: componentLoader("Auth/DirectorsInformation"),
  },
  {
    path: "forgot-password",
    Component: componentLoader("Auth/ForgotPassword"),
  },
  {
    path: "reset-password",
    Component: componentLoader("Auth/ResetPassword"),
  },
  {
    path: "retrieve-username",
    Component: componentLoader("Auth/RetrieveUsername"),
  },
];

export const authWithoutShell = [
  {
    path: "verify-device",
    Component: componentLoader("Auth/VerifyDevice"),
  },

  {
    path: "reset-verify-otp",
    Component: componentLoader("Auth/OtpResetPin"),
  },

  {
    path: "verify-email",
    Component: componentLoader("Auth/VerifyEmail"),
  },
  {
    path: "bvn",
    Component: componentLoader("Auth/BvnPrompt"),
  },
  {
    path: "verify-bvn",
    Component: componentLoader("Auth/ValidateBvn"),
  },
];
