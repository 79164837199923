import { Drawer, Layout } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import HamButton from "../common/Button/HamButton";
import MenuBar from "./MenuBar";
import CloseIcon from "../common/Icons/CloseMenuIcon";
import MenuIcon from "../common/Icons/MenuIcon";
import { motion } from "framer-motion";
import Navbar from "./Navbar";

const { Sider, Content } = Layout;

const SiderBar = ({ children }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [selectedKey, setKey] = useState();
  const ref = useRef();
  const width = window.screen.width;

  function toggle() {
    setOpen((prev) => !prev);
  }

  const setSelectedMenuByLocation = useCallback(() => {
    let path = location.pathname;

    if (path.includes("wallet")) {
      return setKey(["wallet"]);
    } else if (path.includes("cashpoint")) {
      return setKey(["cashpoint"]);
    } else if (path.includes("transactions")) {
      return setKey(["transactions"]);
    } else if (
      path.includes("support") ||
      path.includes("issue") ||
      path.includes("ticket")
    ) {
      return setKey(["support"]);
    } else if (path.includes("profile")) {
      return setKey(["profile"]);
    } else if (path.includes("payout")) {
      return setKey(["payout"]);
    } else if (path.includes("commissions")) {
      return setKey(["commissions"]);
    } else if (path.includes("settlement")) {
      return setKey(["settlement"]);
    } else {
      setKey(["home"]);
    }
  }, [location.pathname]);

  useEffect(() => {
    setSelectedMenuByLocation();
  }, [setSelectedMenuByLocation]);

  const close = useCallback((e) => {
    if (ref.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  }, []);

  useEffect(() => {
    if (width <= 1024) {
      document.addEventListener("mousedown", close);
    }
    return () => {
      document.removeEventListener("mousedown", close);
    };
  }, [width, close]);

  return (
    <Layout>
      <HamButton
        toggle={toggle}
        icon={
          <MenuIcon
            style={{
              color: "black",
            }}
          />
        }
      />

      <div ref={ref}>
        <Drawer
          placement="left"
          onClose={toggle}
          width={200}
          closable={false}
          open={open}
          className="hideOnDesktop"
          bodyStyle={{ padding: "0" }}
        >
          <HamButton toggle={() => setOpen(false)} icon={<CloseIcon />} />
          <MenuBar selectedKey={selectedKey} />
        </Drawer>
      </div>
      <Sider
        theme="light"
        className={
          "sidebar hideOnMobile border-r border-gray-200 border-opacity-50 border-solid py-2"
        }
        trigger={null}
        collapsedWidth="0"
        collapsed={open}
        breakpoint="lg"
      >
        <MenuBar selectedKey={selectedKey} />
      </Sider>
      <Layout className="grupp-content relative bg-[#fff] sm:bg-[#f9f9f9] relative">
        <Navbar />
        <Content className="main-content">
          <motion.div
            key={location.pathname}
            initial="pageInitial"
            animate="pageAnimate"
            variants={{
              pageInitial: {
                opacity: 0.6,
                y: 50,
              },
              pageAnimate: {
                opacity: 1,
                y: 0,
              },
            }}
          >
            {children}
          </motion.div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default SiderBar;
