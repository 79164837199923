import { PhoneIcon, RecipientIcon } from "./common/Icons/PhoneIcon";

export const billAccessType = ["AIRTIME", "CABLE_TV", "DATA", "PHCN"];

export const radioAmount = [
  {
    amount: "200",
    pay: "180",
  },
  {
    amount: "500",
    pay: "480",
  },
  {
    amount: "700",
    pay: "680",
  },
  {
    amount: "1000",
    pay: "980",
  },
  {
    amount: "1500",
    pay: "1480",
  },
  {
    amount: "2000",
    pay: "1980",
  },
];

export const servicePlan = [
  {
    type: "prepaid",
    name: "Prepaid",
  },
  {
    type: "postpaid",
    name: "PostPaid",
  },
];

export const recipientOption = [
  {
    type: "self",
    name: "My Own Number",
    src: <PhoneIcon />,
  },
  {
    type: "thirdparty",
    name: "Third Party Number",
    src: <RecipientIcon />,
  },
];

export const WithdrawalOption = [
  {
    type: "ussd-withdrawal",
    name: "USSD Payment",
  },
  /* {
    type: "mpos-withdrawal",
    name: "mPOS Withdrawal",
  },
  */
];

export const loanOption = [
  {
    title: "See Avaliable Loan",
    type: "airtime",
    route: "request",
  },
  {
    title: "See Running Loans",
    type: "airtime",
    route: "list",
  },
];

export const MerchantCategoryType = [
  "Churches",
  "Fast foods",
  "NGO's",
  "Fuel Stations",
  "Retail stores",
  "Airline Operators",
  "Hotels and Guest Houses",
  "Travel Agencies",
  "Wholesale Category",
  "Others",
];
