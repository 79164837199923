import axios from "axios";
import url from "./url";
import { v4 } from "uuid";
import storage from "redux-persist/lib/storage";

class HttpRequests {
  setBrowserId() {
    if (!localStorage.getItem("grupp.customer.browserId")) {
      return localStorage.setItem("grupp.customer.browserId", v4());
    }
    return localStorage.getItem("grupp.customer.browserId");
  }

  clearData = () => {
    storage.removeItem("persist:root");
    storage.removeItem("grupp.customer.token");
    window.location.reload();
  };

  header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("grupp.customer.token")}`,
  };

  formDataHeader = {
    "Content-Type": "multipart/form-data; boundary=",
    Authorization: `Bearer ${localStorage.getItem("grupp.customer.token")}`,
  };

  request = axios.create({
    baseURL: url.base,
    headers: {
      ...this.header,
      Authorization: `Bearer ${localStorage.getItem("grupp.customer.token")}`,
    },
  });

  requestWithoutHeader = axios.create({
    baseURL: url.base,
    headers: {
      "Content-Type": "application/json",
    },
  });

  requestBlob = axios.create({
    baseURL: url.base,
    headers: {
      ...this.header,
      Authorization: `Bearer ${localStorage.getItem("grupp.customer.token")}`,
    },
    responseType: "blob",
  });

  requestFormData = axios.create({
    baseURL: url.base,
    headers: {
      ...this.formDataHeader,
      Authorization: `Bearer ${localStorage.getItem("grupp.customer.token")}`,
    },
  });

  sendRequest = (method, url, params, data) => {
    this.request.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("grupp.customer.token")}`;
    this.request.defaults.headers["browserId"] = this.setBrowserId();
    const config = {
      method: method,
      params: params,
      data: data,
    };
    return this.request(url, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          this.clearData();
          return Promise.reject(err);
        } else {
          return Promise.reject(err);
        }
      });
  };

  sendRequestWithoutHeader = async (method, url, params, data) => {
    this.request.defaults.headers["browserId"] = this.setBrowserId();
    return this.requestWithoutHeader(url, {
      method: method,
      params: params,
      data: data,
      headers: {
        browserId: this.setBrowserId(),
      },
    })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

  sendFileRequest = (method, url, data) => {
    this.requestFormData.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("grupp.customer.token")}`;
    this.request.defaults.headers["browserId"] = this.setBrowserId();
    let config = { method: method, data: data };
    return this.requestFormData(url, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          this.clearData();
          return Promise.reject(err.response);
        } else {
          return Promise.reject(err);
        }
      });
  };

  sendBlobRequest = (url) => {
    this.requestBlob.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("grupp.customer.token")}`;
    this.request.defaults.headers["browserId"] = this.setBrowserId();
    return this.requestBlob(url)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          return this.clearData();
        } else {
          return Promise.reject(err);
        }
      });
  };

  get = async (url, params, data) => {
    return this.sendRequest("GET", url, params, data);
  };

  getBlob = async (url) => {
    return this.sendBlobRequest(url);
  };

  post = async (url, params, data) => {
    return this.sendRequest("POST", url, params, data);
  };

  postWithoutHeader = async (url, params, data) => {
    return this.sendRequestWithoutHeader("POST", url, params, data);
  };

  postFormData = async (url, data) => {
    return this.sendFileRequest("POST", url, data);
  };

  put = async (url, params, data) => {
    return this.sendRequest("PUT", url, params, data);
  };

  putWithoutHeader = async (url, params, data) => {
    return this.sendRequestWithoutHeader("PUT", url, params, data);
  };

  delete = (url, params, data) => {
    return this.sendRequest("DELETE", url, params, data);
  };
}

export default HttpRequests;
