import { useEffect, useState, useCallback, useRef } from "react";
import { fetchLgaList, fetchStatesList } from "../api/authApi";
import { useSelector } from "react-redux";
import { validateAccount } from "../api/transferApi";
import { defaultAccount } from "./helper";

const initalState = {
  loading: false,
  isLoading: false,
};

/**
 *
 * @param {Form instance} form an antd form instance
 * @param {Array of Strings} optionalFields fields that are not required if any
 * @returns
 */

export const useFormValidation = (form, optionalFields) => {
  const [fields, setFields] = useState(form.getFieldsValue());
  const [valid, setValid] = useState(false);
  let optionals = optionalFields || [];

  const validate = useCallback(() => {
    setFields(form.getFieldsValue());
    let formFieldsValues = Object.values(
      form.getFieldsValue(
        Object.keys(form.getFieldsValue()).filter(
          (field) => !optionals.includes(field)
        )
      )
    );

    if (formFieldsValues.includes(undefined) || formFieldsValues.includes(""))
      return setValid(false);
    setValid(true);
    //eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    validate();
  }, [validate]);

  return { fields, valid, validate };
};

export const screenWidth = () => window.screen.width < 640;

export const useDisClose = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
};

export const useGetLocation = () => {
  const [location, setLocation] = useState({
    state: [],
    lga: [],
  });
  const [loader, setLoader] = useState(initalState);
  const [value, setValue] = useState(null);
  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    async function fetchData() {
      setLoader((prev) => ({
        ...prev,
        [value ? "isLoading" : "loading"]: true,
      }));
      try {
        let response;
        if (value) {
          response = await fetchLgaList(value);
        } else {
          response = await fetchStatesList();
        }
        const options = response.data;
        setLocation((prev) => ({
          ...prev,
          [value ? "lga" : "state"]: options,
        }));
      } catch (error) {
        console.log(error);
      }
      setLoader(initalState);
    }
    fetchData();
    return () => (mounted.current = false);
  }, [value]);

  return {
    ...loader,
    ...location,
    setValue,
  };
};

export const useGetUser = () => {
  const { user, manifest, appLogoUrl } = useSelector((state) => state.auth);
  const ptspCommercialBankMerchantEnabled =
    user?.ptspCommercialBankMerchantEnabled || false;
  const isEnabled = user?.commercialBankMerchantEnabled || false;
  const userType = user.businessType;

  return {
    user,
    ptspCommercialBankMerchantEnabled,
    isEnabled,
    userType,
    manifest,
    appLogoUrl,
  };
};

export const useValidateAccountNumber = (form) => {
  const [validateStatus, setValidateStatus] = useState({
    status: "",
    message: "",
  });
  const [bankInfo, setBankInfo] = useState(defaultAccount);
  const [show, setShow] = useState(false);

  function handleSelection(value) {
    setBankInfo((prev) => ({
      ...prev,
      ...value,
    }));
    setShow(false);
  }

  function handleAccountNumber(event) {
    setBankInfo((prev) => ({
      ...prev,
      accountNumber: event.target.value,
    }));
    setValidateStatus({
      status: "",
    });
  }

  useEffect(() => {
    const validateAccountNumber = async () => {
      const { accountNumber, code } = bankInfo;
      if (code && accountNumber?.length === 10) {
        setValidateStatus({
          status: "validating",
        });
        try {
          const checkResponse = await validateAccount({
            bankCode: code,
            accountNumber: accountNumber,
          });
          if (checkResponse.status) {
            form.setFieldsValue({
              accountName: checkResponse.data.accountName,
            });
            setValidateStatus({
              status: "success",
            });
          }
        } catch (error) {
          form.setFieldsValue({ accountName: null });
          setValidateStatus({
            status: "error",
            message: error.message,
          });
        }
      }
    };

    validateAccountNumber();
  }, [bankInfo, form]);

  return {
    validateStatus,
    bankInfo,
    handleSelection,
    handleAccountNumber,
    show,
    setShow,
  };
};

export default useValidateAccountNumber;
