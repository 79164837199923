import React, { useState } from "react";
import ModalShell from "../../layout/ModalShell";
import CustomInput from "../Form/CustomInput";
import AuthButton from "../Button/AuthButton";
import { Form, notification } from "antd";
import ConfirmUpdate from "./ConfirmUpdate";
import { useDispatch } from "react-redux";
import { updateUsername, validateCheck } from "../../api/authApi";
import { checkUsernameCharacter } from "../../utils/helper";

const UsernameModal = ({ showModal, close, success }) => {
  const [openModal, setOpenModal] = useState(false);
  const [username, setUsername] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validateStatus, setValidateStatus] = useState({ status: "" });
  const dispatch = useDispatch();

  const open = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  const onValuesChange = async (values) => {
    setValidateStatus({
      status: "",
    });

    const { username } = values;

    setValidateStatus({
      status: "validating",
    });
    try {
      await checkUsernameCharacter(username);
      const validateUsername = await validateCheck("username", username);
      setUsername(username);
      setValidateStatus({
        status: "success",
      });
      return Promise.resolve(validateUsername.status);
    } catch (error) {
      setValidateStatus({
        status: "error",
        message: error.message,
      });
    }
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      await dispatch(updateUsername({ username })).unwrap();
      close();
      success();
    } catch (error) {
      notification.error({
        message: "failed to update username",
        description: error.message,
      });
    }
    setLoading(false);
  };

  return (
    <ModalShell
      show={showModal}
      closable={true}
      className="warning rounded-lg filter-modal"
      width={335}
      cancel={close}
    >
      <div className="flex flex-col text-center items-center">
        <p className="text-lg font-bold text-[#021230] text-center">
          Edit your username
        </p>
        <p className="text-[#8291AB] text-[12px] ">
          This username is linked to your account and cannot be changed
        </p>

        <Form
          layout="vertical"
          className="w-full"
          requiredMark={false}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            name="username"
            className="mt-5"
            rules={[{ required: true, message: "Please enter your username" }]}
            help={
              validateStatus?.message ? (
                <span className="text-red-500">{validateStatus?.message}</span>
              ) : null
            }
            label={<div className="form-label">Enter Username</div>}
            hasFeedback
            validateStatus={validateStatus.status}
          >
            <CustomInput required placeholder="Enter Username" />
          </Form.Item>

          <div className="w-full">
            <AuthButton
              className="w-full"
              htmlType="submit"
              loading={loading}
              disabled={validateStatus.status !== "success"}
              onClick={open}
            >
              UPDATE
            </AuthButton>
          </div>
        </Form>
      </div>

      <ConfirmUpdate
        showModal={openModal}
        close={closeModal}
        confirm={onFinish}
      />
    </ModalShell>
  );
};

export default UsernameModal;
