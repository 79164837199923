import { Fragment } from "react";
import { SettingOutlined, TransactionOutlined } from "@ant-design/icons";
import { ConfigProvider, Menu } from "antd";
import Home from "../common/Icons/Home";
import Order from "../common/Icons/Order";
import Wallet from "../common/Icons/Analysis";
import { useNavigate } from "react-router-dom";
import CashpointIcon from "../common/Icons/CashPoint";
import CommissionIcon from "../common/Icons/CommissionIcon";
import Person from "../common/Icons/Person";
import { useGetUser } from "../utils/Hooks";

const MenuBar = ({ selectedKey }) => {
  const navigate = useNavigate();
  const {
    ptspCommercialBankMerchantEnabled,
    isEnabled,
    userType,
    appLogoUrl: businessLogo,
  } = useGetUser();
  const isAgent = userType === "AGENT";

  const items = [
    {
      key: "home",
      icon: <Home />,
      label: "Home",
      disabled: false,
    },
    {
      key: "cashpoint",
      icon: <CashpointIcon />,
      label: "Cashpoint",
      disabled: false,
    },
    {
      key: "transactions",
      icon: <Order />,
      label: "Transaction",
      disabled: false,
    },
    {
      key: "settlement",
      icon: <TransactionOutlined />,
      label: "Settlement",
      disabled: !isEnabled || isAgent,
    },
    {
      key: "wallet",
      icon: <Wallet />,
      label: `${isAgent ? "Wallet" : "Account"}`,
      disabled: isEnabled || ptspCommercialBankMerchantEnabled,
    },
    {
      key: "commissions",
      icon: <CommissionIcon />,
      label: "Cashback",
      disabled: isEnabled || ptspCommercialBankMerchantEnabled,
    },
    {
      key: "support",
      icon: <Person />,
      label: "Support",
      disabled: false,
    },
    {
      key: "profile",
      icon: <SettingOutlined />,
      label: "Settings",
      disabled: false,
    },
  ];

  const filteredItems = items.filter((item) => item.disabled !== true);

  return (
    <Fragment>
      <div className="logo w-full max-h-24 mt-3 pb-10 p-3 flex justify-center">
        <img
          className="max-w-full max-h-full object-contain"
          src={businessLogo}
          alt="logoImage"
        />
      </div>

      <ConfigProvider
        theme={{
          token: {
            borderRadius: 0,
          },
        }}
      >
        <Menu
          theme="light"
          mode="inline"
          className="menu"
          defaultSelectedKeys={["home"]}
          selectedKeys={selectedKey}
          onClick={(value) => navigate(`/${value.key}`)}
          items={filteredItems}
        />
      </ConfigProvider>
    </Fragment>
  );
};

export default MenuBar;
