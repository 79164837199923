import React from "react";
import Icon from "@ant-design/icons";

const AnalysisSvg = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.625 0.75H13.125H12.75H6.75H6.375H4.875C3.63412 0.75 2.625 1.75912 2.625 3V21C2.625 22.2409 3.63412 23.25 4.875 23.25H14.625C15.8659 23.25 16.875 22.2409 16.875 21V18.75H19.5C20.5339 18.75 21.375 17.9089 21.375 16.875V16.125C21.375 15.918 21.207 15.75 21 15.75H19.125V7.875C19.125 6.84113 18.2839 6 17.25 6H16.875V3C16.875 1.75912 15.8659 0.75 14.625 0.75ZM11.694 1.5C11.652 1.61775 11.625 1.743 11.625 1.875C11.625 2.08162 11.4566 2.25 11.25 2.25H8.25C8.04338 2.25 7.875 2.08162 7.875 1.875C7.875 1.743 7.848 1.61775 7.806 1.5H11.694ZM16.125 21C16.125 21.8272 15.4523 22.5 14.625 22.5H4.875C4.04775 22.5 3.375 21.8272 3.375 21V3C3.375 2.17275 4.04775 1.5 4.875 1.5H6.375H6.75C6.95662 1.5 7.125 1.66838 7.125 1.875C7.125 2.49525 7.62975 3 8.25 3H11.25C11.8702 3 12.375 2.49525 12.375 1.875C12.375 1.66838 12.5434 1.5 12.75 1.5H13.125H14.625C15.4523 1.5 16.125 2.17275 16.125 3V6H9.75C8.84475 6 8.08725 6.64537 7.91288 7.5H6C5.793 7.5 5.625 7.668 5.625 7.875V14.625C5.625 14.832 5.793 15 6 15H10.125V14.25H6.375V8.25H10.125V7.5H8.694C8.84925 7.06462 9.26175 6.75 9.75 6.75C10.3703 6.75 10.875 7.25475 10.875 7.875V17.25C10.875 18.0772 11.5477 18.75 12.375 18.75H16.125V21ZM20.625 16.875C20.625 17.4953 20.1203 18 19.5 18H13.6736C13.8019 17.7791 13.875 17.523 13.875 17.25V16.5H20.625V16.875ZM18.375 7.875V15.75H13.5C13.293 15.75 13.125 15.918 13.125 16.125V17.25C13.125 17.6636 12.7886 18 12.375 18C11.9614 18 11.625 17.6636 11.625 17.25V7.875C11.625 7.45125 11.4784 7.06425 11.2403 6.75H17.25C17.8702 6.75 18.375 7.25475 18.375 7.875Z" />
      <path d="M4.875 20.625V3.375C4.875 3.16838 5.04338 3 5.25 3H6V2.25H5.25C4.62975 2.25 4.125 2.75475 4.125 3.375V20.625C4.125 21.2453 4.62975 21.75 5.25 21.75H13.875V21H5.25C5.04338 21 4.875 20.8316 4.875 20.625Z" />
      <path d="M9.375 9.75H9V9.375H8.25V9.75H7.875C7.668 9.75 7.5 9.918 7.5 10.125V11.25C7.5 11.457 7.668 11.625 7.875 11.625H9V12H7.5V12.375C7.5 12.582 7.668 12.75 7.875 12.75H8.25V13.125H9V12.75H9.375C9.582 12.75 9.75 12.582 9.75 12.375V11.25C9.75 11.043 9.582 10.875 9.375 10.875H8.25V10.5H9.75V10.125C9.75 9.918 9.582 9.75 9.375 9.75Z" />
      <path d="M6 15.75H10.125V16.5H6V15.75Z" />
      <path d="M6 17.25H10.125V18H6V17.25Z" />
      <path d="M6 18.75H10.125V19.5H6V18.75Z" />
      <path d="M15 14.625C16.4475 14.625 17.625 13.4475 17.625 12C17.625 10.5525 16.4475 9.375 15 9.375C13.5525 9.375 12.375 10.5525 12.375 12C12.375 13.4475 13.5525 14.625 15 14.625ZM16.0605 12.375H16.8371C16.8019 12.5471 16.7362 12.7061 16.6575 12.8576L16.0605 12.375ZM16.8371 11.625H15.375V10.1629C16.1085 10.3125 16.6875 10.8915 16.8371 11.625ZM14.625 10.1629V12C14.625 12.1177 14.6828 12.2186 14.7679 12.2872L14.7645 12.2917L16.1857 13.4407C15.8614 13.7077 15.4519 13.875 15 13.875C13.9661 13.875 13.125 13.0339 13.125 12C13.125 11.0947 13.7704 10.3372 14.625 10.1629Z" />
      <path d="M6 4.125H15V4.875H6V4.125Z" />
    </svg>
  );
};

const Analysis = (props) => <Icon component={AnalysisSvg} {...props} />;

export default Analysis;
