import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SiderBar from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLogoutModal,
  onHandleUserNameModal,
} from "../app/features/setttingSlice";
import UpdateUserNameModal from "../common/Modal/UsernameModal";
import { useEffect, useMemo, useState } from "react";
import {
  fetchBankList,
  fetchTransactionStatus,
  fetchTransactionTypes,
} from "../api/transactionsApi";
import { notification } from "antd";
import { fetchAllTicketCategories } from "../app/features/supportSlice";
import { billAccessType } from "../mockData";
import { getFCMToken, onMessageListener } from "../firebase";
import { CloseOutlined, SmileOutlined } from "@ant-design/icons";
import { getNotificationCount } from "../api/notificationApi";
import { AccountType, AccountTypeStatus, UserKycStatus } from "../utils/helper";
import LogoutModal from "../common/Modal/LogoutModal";
import KycInformationModal from "../common/Modal/KycInformationModal";
import SuccessModal from "../common/Modal/SuccessModal";
import { useGetUser } from "../utils/Hooks";

const unAuthorizedMerchantRoute = [
  "loan",
  "pay-bills",
  "confirm-transaction",
  "account",
];

const DashboardShell = () => {
  const { onOpen, show } = useSelector((s) => s.settings);
  const { user, isEnabled } = useGetUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = location.pathname;
  const isBillAccessibile = useMemo(
    () => billAccessType.some((arr) => user?.serviceAccess?.includes(arr)),
    [user?.serviceAccess]
  );
  const [showOpen, setShowOpen] = useState(false);
  const bvnVerification =
    !user.bvnVerified && !isEnabled && !user?.onboardedViaAccountInfo;
  const isProfile = path.includes("profile");

  const showSuccess = () => {
    setShowOpen(true);
  };

  const closeShow = () => {
    setShowOpen(false);
  };

  function onCloseModal() {
    dispatch(onHandleUserNameModal(false));
  }

  function close() {
    dispatch(handleLogoutModal(false));
  }

  onMessageListener()
    .then(async (payload) => {
      await getNotificationCount();
      const title = payload.notification.title;
      notification.open({
        message: title,
        description: payload.notification.body,
        style: { backgroundColor: "#f9f9f9" },
        icon: title.includes(AccountTypeStatus.DECLINED) ? (
          <CloseOutlined style={{ color: "red" }} />
        ) : (
          <SmileOutlined style={{ color: "#108ee9" }} />
        ),
      });
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    async function getCachedData() {
      try {
        const promises = [
          dispatch(fetchBankList()).unwrap(),
          dispatch(fetchAllTicketCategories()).unwrap(),
          dispatch(fetchTransactionTypes(isEnabled)).unwrap(),
          dispatch(fetchTransactionStatus()).unwrap(),
          //dispatch(getCustomerInfo()).unwrap(),
          getFCMToken(),
        ];

        const promiseResults = await Promise.all(
          promises.map((promise) =>
            Promise.resolve(promise).then(
              (value) => ({ status: "fulfilled", value }),
              (reason) => ({ status: "rejected", reason })
            )
          )
        );

        promiseResults.forEach((result) => {
          if (result.status === "rejected") {
            notification.error({
              message: "Error!",
              description: result.reason.message,
            });
          }
        });
      } catch (error) {
        notification.error({ message: "Error!", description: error.message });
      }
    }
    getCachedData();
  }, [isEnabled, dispatch]);

  useEffect(() => {
    let pathArr = path.split("/");

    if (bvnVerification) {
      return navigate("/profile/director");
    }

    if (
      user?.accountType === AccountType.BUSINESS &&
      user?.accountTypeStatus !== AccountTypeStatus.APPROVED
    ) {
      return navigate("/profile");
    }

    if (
      user.category === "MERCHANT" &&
      isEnabled &&
      unAuthorizedMerchantRoute.includes(pathArr[1])
    ) {
      return navigate(-1);
    }
    if (
      path.includes("ussd-withdrawal") &&
      !user?.serviceAccess?.includes("WITHDRAWAL")
    ) {
      return navigate(-1);
    }

    if (
      path.includes("transfer") &&
      !user?.serviceAccess?.includes("TRANSFER")
    ) {
      return navigate(-1);
    }
    if (path.includes("pay-bills") && !isBillAccessibile) {
      return navigate(-1);
    }
    /*
   if (path.includes("pay-bills") && pathArr.length >= 3) {
      routePath = pathArr[2].toUpperCase().replaceAll("-", "_");
      if (!user?.serviceAccess?.includes(routePath)) return navigate(-1);
    }*/

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, user, isEnabled]);

  const showKycDeclineModal =
    !isProfile && user?.kycStatus === UserKycStatus.DECLINED;

  return (
    <div>
      <SiderBar>
        <Outlet />
      </SiderBar>
      <UpdateUserNameModal
        showModal={onOpen}
        close={onCloseModal}
        success={showSuccess}
      />
      <LogoutModal open={show} close={close} />
      {showKycDeclineModal && (
        <KycInformationModal
          open={showKycDeclineModal}
          reason={user?.kycStatusMessage}
        />
      )}
      <SuccessModal
        show={showOpen}
        onCancel={closeShow}
        text={"Username Successfully Created"}
        message={`You can now use this unique username to access your account`}
      />
    </div>
  );
};
export default DashboardShell;
