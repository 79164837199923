import { Input } from "antd";

const CustomInput = ({
  placeholder,
  hasValue,
  onChange,
  value,
  bottomTag,
  span,
  className,
  max,
  disabled,
  inputMode = "text",
  ...props
}) => {
  return (
    <div>
      <Input
        value={value ?? value}
        className={`auth-input ${hasValue ? "filled" : ""} ${
          className ? className : ""
        }`}
        placeholder={placeholder}
        inputMode={inputMode}
        onChange={onChange ?? onChange}
        maxLength={max}
        disabled={disabled ?? disabled}
        {...props}
      />
      {bottomTag ?? bottomTag}
      <span className="text-blue-400 mt-1">{span}</span>
    </div>
  );
};

export default CustomInput;
