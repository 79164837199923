import { createAsyncThunk } from "@reduxjs/toolkit";
import url from "../utils/url";
import HttpRequest from "../utils/HttpRequests";
import { TransactionType } from "../utils/helper";

const Request = new HttpRequest();

export const fetchTransactionDetails = async (ref, query) => {
  try {
    const tnx = await Request.get(`/${url.transaction}/${ref}/details`, query);
    return Promise.resolve(tnx);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTransactionReceipt = async (data) => {
  try {
    const tnx = await Request.post(`/${url.transaction}/receipt`, null, data);
    return Promise.resolve(tnx);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to send receipt",
    });
  }
};

const commercialMerchantTransactionType = (list) => {
  const newTransactionType = [];

  for (let i = 0; i < list.length; i++) {
    const type = list[i].type;
    switch (type) {
      case TransactionType.WITHDRAWAL:
        newTransactionType.push({ friendly: "POS Purchase", type });
        break;
      case TransactionType.WALLET_TOP_UP:
        newTransactionType.push({ friendly: "Payment Via Transfer", type });
        break;
      case TransactionType.PAYOUT:
        newTransactionType.push({ friendly: "Payout", type });
        break;
      case TransactionType.SETTLEMENT:
        newTransactionType.push({ friendly: "Settlement", type });
        break;
      case TransactionType.NAIRA_INTL_WITHDRAWAL:
        newTransactionType.push({ friendly: "Naira Intl. Purchase", type });
        break;
      case TransactionType.USD_INTL_WITHDRAWAL:
        newTransactionType.push({ friendly: "USD Intl. Purchase", type });
        break;
      // no default
    }
  }

  return newTransactionType;
};

export const fetchTransactionTypes = createAsyncThunk(
  "transaction/getTransactionTypes",
  async (isEnabled, { rejectWithValue }) => {
    try {
      const tnx = await Request.get(`/${url.transaction}/type`);
      if (isEnabled) {
        const result = commercialMerchantTransactionType(tnx.data);
        return result;
      } else {
        return tnx.data;
      }
    } catch (error) {
      if (error.response) {
        rejectWithValue({ message: error.response?.data?.message });
      }
      rejectWithValue(error);
    }
  }
);

export const makeUssdWithdrawalTransaction = async (data) => {
  try {
    const tnxResponse = await Request.post(`/${url.transaction}`, null, data);
    return Promise.resolve(tnxResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTransactionSummary = async (query) => {
  try {
    const tnxResponse = await Request.get(`/${url.transaction}/stats`, query);
    return Promise.resolve(tnxResponse);
  } catch (error) {
    return Promise.reject({
      statusCode: error.response?.status,
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const fetchUssdSupportedBank = createAsyncThunk(
  "transaction/getUssdBanks",
  async (_, { rejectWithValue }) => {
    try {
      const tnxResponse = await Request.get(`/${url.transaction}/ussd-bank`);
      return tnxResponse.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchTransactionStatus = createAsyncThunk(
  "transaction/getTransactionStatus",
  async (ref, { rejectWithValue }) => {
    try {
      const tnxResponse = await Request.get(`/${url.transaction}/status`);
      return tnxResponse.data;
    } catch (error) {
      rejectWithValue({ message: error.response?.data?.message });
    }
  }
);

export const fetchBankList = createAsyncThunk(
  "transaction/bankList",
  async (_, { rejectWithValue }) => {
    try {
      const dataResponse = await Request.get(`/${url.transaction}/banks`);
      return dataResponse.data;
    } catch (error) {
      if (error.response) {
        rejectWithValue({ message: error.response?.data?.message });
      }
      rejectWithValue(error);
    }
  }
);

export const downloadTransaction = async (data, query) => {
  try {
    const reportResponse = await Request.post(
      `/${url.transaction}/download`,
      query,
      data
    );
    return Promise.resolve(reportResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to send report",
    });
  }
};

export const getBankNetworkList = async () => {
  try {
    const listResponse = await Request.get(`/${url.transaction}/bank-metrics`);
    return Promise.resolve(listResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response.data.message || "Something went wrong",
    });
  }
};
