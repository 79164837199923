import { Button } from "antd";

function HamButton({ toggle, icon }) {
  return (
    <Button
      className="text-black fixed w-32 h-32 border-0 lg:hidden shadow-none flex items-center 
          justify-center bg-[#fff] rounded-full z-50"
      type="primary"
      size="large"
      icon={icon}
      ghost
      onClick={toggle}
    />
  );
}

export default HamButton;
