import React from "react";
import Icon from "@ant-design/icons";

const PhoneSvg = () => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 40.7998C31.0457 40.7998 40 31.8455 40 20.7998C40 9.75411 31.0457 0.799805 20 0.799805C8.9543 0.799805 0 9.75411 0 20.7998C0 31.8455 8.9543 40.7998 20 40.7998Z"
        fill="#F7F7FB"
      />
      <g clipPath="url(#clip0_1366_217226)">
        <path
          d="M28.4211 23.9682C27.2025 23.9706 25.9914 23.778 24.8337 23.3977C24.2924 23.2084 23.6908 23.3358 23.2726 23.7282L21.0011 25.4419C18.5616 24.2171 16.5821 22.2381 15.3569 19.7988L17.0253 17.5819C17.4465 17.1604 17.5972 16.539 17.4158 15.9714C17.0341 14.8121 16.8404 13.5992 16.8421 12.3788C16.8421 11.5067 16.1352 10.7998 15.2632 10.7998H11.5789C10.7069 10.7998 10 11.5067 10 12.3788C10.0116 22.5476 18.2522 30.7882 28.4211 30.7998C29.2931 30.7998 30 30.0929 30 29.2209V25.5472C30 24.6752 29.2931 23.9682 28.4211 23.9682Z"
          fill="#26847D"
        />
        <path
          d="M29.4737 19.2209C29.183 19.2209 28.9473 18.9852 28.9473 18.6945C28.9427 14.9177 25.8821 11.8571 22.1052 11.8524C21.8145 11.8524 21.5789 11.6168 21.5789 11.3261C21.5789 11.0354 21.8145 10.7998 22.1052 10.7998C26.4634 10.8044 29.9953 14.3363 29.9999 18.6945C29.9999 18.9852 29.7643 19.2209 29.4737 19.2209Z"
          fill="#26847D"
        />
        <path
          d="M25.2631 19.2208C24.9724 19.2208 24.7367 18.9852 24.7367 18.6945C24.7367 17.2411 23.5586 16.0629 22.1052 16.0629C21.8145 16.0629 21.5789 15.8273 21.5789 15.5366C21.5789 15.2459 21.8145 15.0103 22.1052 15.0103C24.14 15.0103 25.7894 16.6598 25.7894 18.6945C25.7894 18.9852 25.5538 19.2208 25.2631 19.2208Z"
          fill="#26847D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1366_217226">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(10 10.7998)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const RecipientSvg = () => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 40.8C31.0457 40.8 40 31.8457 40 20.8C40 9.75435 31.0457 0.800049 20 0.800049C8.9543 0.800049 0 9.75435 0 20.8C0 31.8457 8.9543 40.8 20 40.8Z"
        fill="#F7F7FB"
      />
      <path
        d="M25.1731 33H16.1511C15.4914 32.9996 14.8587 32.7353 14.3921 32.2651C13.9254 31.7949 13.6629 31.1573 13.6621 30.4921V12.5094C13.6625 11.844 13.9249 11.2059 14.3916 10.7354C14.8583 10.2649 15.4911 10.0004 16.1511 10H25.1731C25.8331 10.0004 26.466 10.2649 26.9327 10.7354C27.3994 11.2059 27.6617 11.844 27.6621 12.5094V30.4982C27.6597 31.1623 27.3965 31.7984 26.93 32.2673C26.4635 32.7361 25.8318 32.9996 25.1731 33ZM20.6614 29.3251C20.3836 29.3254 20.1122 29.4087 19.8814 29.5645C19.6506 29.7202 19.4707 29.9415 19.3646 30.2002C19.2584 30.459 19.2307 30.7437 19.285 31.0183C19.3392 31.2929 19.473 31.5451 19.6694 31.7431C19.8658 31.9411 20.116 32.076 20.3884 32.1307C20.6608 32.1854 20.9432 32.1575 21.1998 32.0505C21.4565 31.9435 21.6759 31.7621 21.8304 31.5294C21.9849 31.2967 22.0675 31.0231 22.0678 30.7431C22.0678 30.367 21.9196 30.0064 21.6559 29.7404C21.3921 29.4745 21.0344 29.3251 20.6614 29.3251ZM16.7404 11.6538C16.5341 11.6493 16.3345 11.7275 16.1852 11.8711C16.036 12.0148 15.9493 12.2122 15.9442 12.4202V27.366C15.9493 27.5738 16.036 27.7711 16.1853 27.9146C16.3346 28.058 16.5342 28.1358 16.7404 28.1309H24.5838C24.7901 28.1358 24.9899 28.058 25.1394 27.9146C25.289 27.7713 25.376 27.574 25.3815 27.366V12.4187C25.3764 12.2104 25.2895 12.0128 25.14 11.8691C24.9904 11.7254 24.7904 11.6474 24.5838 11.6523L16.7404 11.6538Z"
        fill="#26847D"
      />
    </svg>
  );
};

const SupportPhone = () => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 40.2552C31.0457 40.2552 40 31.3009 40 20.2552C40 9.20955 31.0457 0.255249 20 0.255249C8.9543 0.255249 0 9.20955 0 20.2552C0 31.3009 8.9543 40.2552 20 40.2552Z"
        fill="#F7F7FB"
      />
      <path
        d="M24.236 30.542H16.2133C15.6267 30.5416 15.0641 30.3085 14.6491 29.8938C14.2342 29.4791 14.0007 28.9167 14 28.33V12.4687C14.0004 11.8818 14.2337 11.319 14.6487 10.904C15.0637 10.489 15.6264 10.2557 16.2133 10.2553H24.236C24.8229 10.2557 25.3857 10.489 25.8007 10.904C26.2157 11.319 26.449 11.8818 26.4493 12.4687V28.3353C26.4472 28.9211 26.2131 29.4821 25.7983 29.8957C25.3835 30.3093 24.8218 30.5417 24.236 30.542ZM20.224 27.3007C19.977 27.3009 19.7357 27.3744 19.5304 27.5118C19.3252 27.6492 19.1652 27.8443 19.0708 28.0725C18.9765 28.3007 18.9518 28.5518 19.0001 28.7941C19.0483 29.0363 19.1673 29.2588 19.3419 29.4334C19.5166 29.6081 19.7391 29.727 19.9813 29.7753C20.2235 29.8235 20.4746 29.7989 20.7028 29.7045C20.931 29.6101 21.1262 29.4502 21.2636 29.2449C21.4009 29.0397 21.4744 28.7983 21.4747 28.5513C21.4747 28.2196 21.3429 27.9015 21.1084 27.667C20.8738 27.4324 20.5557 27.3007 20.224 27.3007ZM16.7373 11.714C16.5539 11.7101 16.3764 11.779 16.2437 11.9057C16.1109 12.0324 16.0339 12.2066 16.0293 12.39V25.5727C16.0339 25.756 16.111 25.93 16.2437 26.0565C16.3765 26.183 16.554 26.2516 16.7373 26.2473H23.712C23.8954 26.2516 24.0731 26.183 24.2061 26.0566C24.339 25.9301 24.4164 25.7561 24.4213 25.5727V12.3887C24.4168 12.205 24.3395 12.0306 24.2065 11.9039C24.0735 11.7771 23.8957 11.7084 23.712 11.7127L16.7373 11.714Z"
        //fill="#26847D"
      />
    </svg>
  );
};

export const PhoneIcon = (props) => <Icon component={PhoneSvg} {...props} />;

export const RecipientIcon = (props) => (
  <Icon component={RecipientSvg} {...props} />
);

export const SupportPhoneIcon = (props) => (
  <Icon {...props} component={SupportPhone} />
);
