import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  params: {},
};

export const loansSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    filterLoan: (state, { payload }) => {
      state.params = payload;
    },
  },
});

export const { filterLoan } = loansSlice.actions;
export default loansSlice.reducer;
