import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import BackButton from "../common/Button/BackButton";
import Logo from "../assets/logo/Grupp-logo.svg";

const BlankPageShell = () => {
  const appLogoUrl = useSelector((s) => s.auth.appLogoUrl);

  return (
    <div className="w-full md:h-screen h-auto md:py-18 py-5 px-5 relative bg-white">
      <BackButton btnPosition="top-10 md:top-32" />
      <div className="mb-8">
        <div className="flex justify-center md:block mb-3">
          <div className="h-14 w-24 md:h-24 md:w-36">
            <img
              className="max-w-full max-h-full mx-auto"
              src={appLogoUrl || Logo}
              alt="logo"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Outlet />
      </div>
    </div>
  );
};

export default BlankPageShell;
