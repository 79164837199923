import { LeftOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import arrow from "../../assets/svg/eva_arrow-back-outline.svg";
import { useSelector } from "react-redux";

const BackButton = ({
  className,
  state,
  handleState,
  btnPosition = "top-0",
}) => {
  const navigate = useNavigate();
  const mobile = useSelector((s) => s.dashboard?.isMobile);

  return (
    <div className={`absolute ${btnPosition} `}>
      <button
        className={
          "flex items-center justify-center text-sm mr-1 border-none shadow-none cursor-pointer hover:text-indigo-300 mb-0 p-0 " +
          className
        }
        type="ghost"
        onClick={() => (state ? handleState() : navigate(-1))}
      >
        {!mobile ? (
          <div className="flex items-center justify-center">
            <LeftOutlined style={{ marginBottom: "1px" }} /> Back
          </div>
        ) : (
          <img src={arrow} alt="arrow" />
        )}
      </button>
    </div>
  );
};

export default BackButton;
