import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import dashboardReducer from "./features/dashboardSlice";
import transactionReducer from "./features/transactionSlice";
import billsReducer from "./features/billsSlice";
import supportReducer from "./features/supportSlice";
import notificationReducer from "./features/notificationSlice";
import loansReducer from "./features/loansSlice";
import settingReducer from "./features/setttingSlice";
import commissionReducer from "./features/commissionSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  transaction: transactionReducer,
  bills: billsReducer,
  commission: commissionReducer,
  support: supportReducer,
  notification: notificationReducer,
  loan: loansReducer,
  settings: settingReducer,
});

export default rootReducer;
