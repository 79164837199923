import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import url from "../../utils/url";
import HttpRequests from "../../utils/HttpRequests";
const baseUrl = url.ticket;
const Request = new HttpRequests();

const initialState = {
  issueInfo: null,
  ticketCategories: [],
  transactionDetails: {},
  issueType: null,
  showTicketForm: false,
};

export const fetchAllTicketCategories = createAsyncThunk(
  "ticketCategories",
  async (_, { rejectWithValue }) => {
    try {
      let result = await Request.get(`/${baseUrl}/categories`, null, null);
      if (result.status) return result;
    } catch (err) {
      return rejectWithValue({
        statusCode: err?.response?.status || 400,
        message:
          err?.response?.data?.message ||
          "Something went wrong, please refresh page",
      });
    }
  }
);

const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    setTicketCategories: (state, action) => {
      state.ticketCategories = action.payload;
    },
    setTransactionDetails: (state, action) => {
      state.transactionDetails = action.payload;
    },
    updateIssueSelected: (state, { payload }) => {
      state.issueType = payload;
      state.showTicketForm = true;
    },
    hideTicketForm: (state) => {
      state.showTicketForm = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllTicketCategories.pending, (state) => {
      state.ticketCategories = [];
    });
    builder.addCase(
      fetchAllTicketCategories.fulfilled,
      (state, { payload }) => {
        state.ticketCategories = payload.data;
      }
    );
  },
});

export const {
  setTicketCategories,
  setTransactionDetails,
  updateIssueSelected,
  hideTicketForm,
} = supportSlice.actions;

export default supportSlice.reducer;
