import React from "react";
import ModalShell from "../../layout/ModalShell";
import AuthButton from "../Button/AuthButton";

const ConfirmUpdate = ({ showModal, close, confirm }) => {
  return (
    <ModalShell
      show={showModal}
      closable={false}
      className={"warning rounded-lg mt-[50%] md:mt-[10%]"}
      style={{ borderRadius: "16px" }}
    >
      <div className="flex flex-col items-center py-1 gap-2">
        <h2 className="text-xl font-semibold mt-2">Are you sure?</h2>
        <p className="text-[#8291AB] leading-6 mb-3 text-center">
          Your username can be used for login and cannot be changed
        </p>
        <div className="w-full flex gap-1">
          <button className="w-1/2" onClick={close}>
            Back
          </button>
          <AuthButton
            className="w-1/2"
            onClick={() => {
              confirm();
              close();
            }}
          >
            YES, UPDATE
          </AuthButton>
        </div>
      </div>
    </ModalShell>
  );
};

export default ConfirmUpdate;
