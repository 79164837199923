import { pushNotificationCount } from "../app/features/notificationSlice";
import store from "../app/store";
import HttpRequest from "../utils/HttpRequests";

const { dispatch } = store;
const Request = new HttpRequest();

export const updateNotificationAsRead = async (id) => {
  try {
    const response = await Request.put(`/notifications/${id}`);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getNotificationListing = async (params) => {
  try {
    const response = await Request.get(`/notifications`, params, null);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getNotificationCount = async (params) => {
  try {
    const result = await Request.get(`/notifications/count`, null, null);
    dispatch(pushNotificationCount(result.data));
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject({ message: err.response.data.message || "Error" });
  }
};
