import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpRequests from "../utils/HttpRequests";
import url from "../utils/url";

const Request = new HttpRequests();

export const fetchAllTransactions = async (query) => {
  try {
    const tnxResponse = await Request.get(`/${url.transaction}`, query);
    return Promise.resolve(tnxResponse);
  } catch (err) {
    return Promise.reject({
      statusCode: err.response.status,
      message:
        err.response?.data?.message ||
        "Something went wrong, please refresh page",
    });
  }
};

export const fetchWalletBalance = createAsyncThunk(
  "dashboard/getWalletBalance",
  async (option = "customer", { rejectWithValue }) => {
    try {
      const response = await Request.get(`/${url.wallet}/${option}/balance`);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        statusCode: err.response.status,
        message:
          err.response?.data?.message ||
          "Something went wrong, please refresh page",
      });
    }
  }
);
