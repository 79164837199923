import { Modal } from "antd";
import React from "react";

const ModalShell = ({
  show,
  cancel,
  children,
  loading,
  closeIcon,
  closable,
  footer,
  className,
  style,
  title,
  width = 520,
  maskBgColor = "#00000073",
  blur = "blur(2px)",
  ...props
}) => {
  return (
    <Modal
      open={show}
      onCancel={cancel}
      width={width}
      okButtonProps={{ loading: loading }}
      title={title ?? title}
      closeIcon={closeIcon}
      closable={closable}
      footer={footer ? footer : ""}
      className={className}
      bodyStyle={style}
      maskStyle={{ background: maskBgColor, backdropFilter: blur }}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default ModalShell;
