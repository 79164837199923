import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppManifest } from "./manifest";
import { getManifest } from "./api/authApi";
import { setDomain } from "./app/features/authSlice";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import PageFallback from "./common/Fallback/PageFallback";
import PageRoutes from "./routes/PageRoutes";
import { handleViewPoint } from "./app/features/dashboardSlice";
import { useLocation } from "react-router-dom";

const App = () => {
  const appDomain = useSelector((s) => s.auth?.subdomain);
  const dispatch = useDispatch();
  const isProduction = process.env.NODE_ENV === "production";
  const version = packageInfo.version;
  const path = useLocation().pathname;

  const setManifest = useCallback(
    async function () {
      if (!appDomain) return;
      try {
        let manifest = await dispatch(getManifest(appDomain)).unwrap();
        setAppManifest(manifest.data);
      } catch (error) {
        console.log(error);
      }
    },
    [appDomain, dispatch]
  );

  useEffect(() => {
    let subdomain = window.location.hostname.split(".")[0];
    if (!appDomain || subdomain !== appDomain) {
      dispatch(setDomain(subdomain));
    }
    setManifest();
  }, [setManifest, appDomain, dispatch]);

  useEffect(() => {
    function handleResize() {
      dispatch(
        handleViewPoint({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      );
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, [path]);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<PageFallback />}
    >
      <PageRoutes />
    </CacheBuster>
  );
};

export default App;
