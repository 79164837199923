import React from "react";
import Icon from "@ant-design/icons";

const OrderSvg = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.04 14.05C17.62 14.46 17.38 15.05 17.44 15.68C17.53 16.76 18.52 17.55 19.6 17.55H21.5V18.74C21.5 20.81 19.81 22.5 17.74 22.5H7.63C7.94 22.24 8.21 21.92 8.42 21.56C8.79 20.96 9 20.25 9 19.5C9 17.29 7.21 15.5 5 15.5C4.06 15.5 3.19 15.83 2.5 16.38V12.01C2.5 9.94001 4.19 8.25 6.26 8.25H17.74C19.81 8.25 21.5 9.94001 21.5 12.01V13.45H19.48C18.92 13.45 18.41 13.67 18.04 14.05Z"
        stroke="#969BA0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 12.9101V8.3401C2.5 7.1501 3.23 6.09006 4.34 5.67006L12.28 2.67006C13.52 2.20006 14.85 3.12009 14.85 4.45009V8.25008"
        stroke="#969BA0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5588 14.4702V16.5302C22.5588 17.0802 22.1188 17.5302 21.5588 17.5502H19.5988C18.5188 17.5502 17.5288 16.7602 17.4388 15.6802C17.3788 15.0502 17.6188 14.4602 18.0388 14.0502C18.4088 13.6702 18.9188 13.4502 19.4788 13.4502H21.5588C22.1188 13.4702 22.5588 13.9202 22.5588 14.4702Z"
        stroke="#969BA0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12.5H14"
        stroke="#969BA0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 19.5C9 20.25 8.79 20.96 8.42 21.56C8.21 21.92 7.94 22.24 7.63 22.5C6.93 23.13 6.01 23.5 5 23.5C3.54 23.5 2.27 22.72 1.58 21.56C1.21 20.96 1 20.25 1 19.5C1 18.24 1.58 17.11 2.5 16.38C3.19 15.83 4.06 15.5 5 15.5C7.21 15.5 9 17.29 9 19.5Z"
        stroke="#969BA0"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.07007 20.5399L3.95007 18.4299"
        stroke="#969BA0"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.05005 18.46L3.93005 20.5699"
        stroke="#969BA0"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CommissionIcon = (props) => <Icon component={OrderSvg} {...props} />;

export default CommissionIcon;
