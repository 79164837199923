import { createSlice } from "@reduxjs/toolkit";
import {
  getVirtualAccountStatus,
  handleVirtualAccountRequest,
} from "../../api/accountApi";

const initialState = {
  showUpdateModal: false,
  profile: {},
  onOpen: false,
  dataType: {
    label: "",
    title: "",
    name: "",
    height: 280,
  },
  accountStep: 0,
  businessInfo: {},
  openModal: false,
  accountResponse: {},
  show: false,
};

export const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    displayProfileModal: (state, { payload }) => {
      state.dataType = {
        ...state.dataType,
        ...payload,
      };
      state.showUpdateModal = true;
    },
    closeUpdateModal: (state) => {
      state.dataType = {
        label: "",
        title: "",
        name: "",
      };
      state.showUpdateModal = false;
    },
    onHandleUserNameModal: (state, { payload }) => {
      state.onOpen = payload;
    },
    updateAccountStep: (state, { payload }) => {
      state.accountStep++;
      state.businessInfo = {
        ...state.businessInfo,
        ...payload,
      };
    },
    handleBackDrawer: (state) => {
      state.accountStep--;
    },
    closeStep: (state) => {
      state.accountStep = 0;
    },
    closeModal: (state) => {
      state.accountResponse = {};
      state.openModal = false;
    },
    handleLogoutModal: (state, { payload }) => {
      state.show = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleVirtualAccountRequest.fulfilled, (state) => {
      state.openModal = true;
      state.accountResponse = {
        title: "Business Account Processing",
        text: "You have successfully applied to generate  a business account. This request is processing.",
        status: "Pending",
      };
    });

    builder.addCase(getVirtualAccountStatus.fulfilled, (state, { payload }) => {
      const userResponse = payload.data;
      let response = {};

      switch (userResponse.accountTypeStatus) {
        case "PENDING":
          response = {
            title: "Business Account Processing",
            text: "You have successfully applied to generate a business account. This request is processing.",
            status: "Pending",
          };
          state.openModal = true;
          break;

        case "DECLINED":
          response = {
            title: "Account Creation Declined",
            text: "Your request to upgrade to a business account was declined. Kindly try again.",
            status: "Declined",
          };
          state.openModal = true;
          break;

        case "APPROVED":
          response = {
            title: "Congratulations, you now have a business account",
            text: "Your account has been successfully generated. These are your updated business account details.",
            status: "Approved",
            accountName: userResponse.accountName,
            accountNumber: userResponse.accountNumber,
            bank: userResponse.bank,
          };
          state.openModal = true;
          break;

        default:
          response = {};
          break;
      }
      state.accountResponse = { ...response };
    });
  },
});

export const {
  displayProfileModal,
  closeUpdateModal,
  onHandleUserNameModal,
  updateAccountStep,
  closeStep,
  handleBackDrawer,
  closeModal,
  handleLogoutModal,
} = settingSlice.actions;

export default settingSlice.reducer;
