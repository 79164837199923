import { createSlice } from "@reduxjs/toolkit";
import {
  browserValidation,
  getManifest,
  loginUser,
  registerUser,
  updateUsername,
} from "../../api/authApi";
import {
  generateBusinessAccount,
  getCustomerInfo,
  handleVirtualAccountRequest,
} from "../../api/accountApi";
import { checkRCPrefix } from "../../utils/helper";

const initialState = {
  authenticated: false,
  authLevel: "",
  appLogoUrl: "",
  appName: "Grupp",
  manifest: {},
  subdomain: "",
  user: {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    bvnVerified: false,
  },
  visiblity: true,
  commissionVisibility: false,
  formInfo: {},
  otpExpiration: 1610374821180,
  browserRequestID: "",
  userWithUsername: null,
  cachedUser: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.authenticated = false;
      state.user = {};
      localStorage.removeItem("grupp.customer.token");
    },
    setUserType: (state, action) => {
      state.authLevel = action.payload;
    },
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    setDomain: (state, action) => {
      state.subdomain = action.payload;
    },
    setUserBvnStatus: (state, action) => {
      state.user.bvnVerified = action.payload;
    },
    setBrowserRequestId: (state, action) => {
      state.browserRequestID = action.payload;
    },
    setFormInput: (state, action) => {
      state.formInfo = { ...action.payload };
    },

    updateUserBvnStatus: (state, action) => {
      state.user.bvnVerified = action.payload;
    },
    toggleVisibility: (state, action) => {
      state.visiblity = action.payload;
    },
    toggleCommissionVisibility: (state, action) => {
      state.commissionVisibility = action.payload;
    },
    updateBusiness: (state, { payload }) => {
      state.user = {
        ...state.user,
        firstName: payload.firstName,
        lastName: payload.lastName,
        address: payload.store[0].address || "",
        businessName: payload.businessName,
        state: payload.store[0].state || "",
        lga: payload.store[0].lga || "",
        profileImageId: payload.profileImageId,
      };
    },
    cacheUserInfo: (state, { payload }) => {
      state.cachedUser = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getManifest.fulfilled, (state, { payload }) => {
      state.appName = payload.data.short_name;
      state.appLogoUrl = payload.data.icons[0]?.src;
      state.manifest = payload.data;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      const responseData = payload.data;
      state.authenticated = true;
      state.user = responseData;
      state.cachedUser = {
        phoneNumber: responseData.phoneNumber,
        name: responseData.firstName,
        color: responseData?.color,
        username: responseData?.username,
      };
      state.userWithUsername = responseData?.username;
    });
    builder.addCase(browserValidation.fulfilled, (state, { payload }) => {
      const responseData = payload.data;
      state.authenticated = true;
      state.user = responseData;
      state.cachedUser = {
        phoneNumber: responseData.phoneNumber,
        name: responseData.firstName,
        color: responseData?.color,
        username: responseData?.username,
      };
      state.userWithUsername = responseData?.username;
    });
    builder.addCase(registerUser.fulfilled, (state) => {
      state.userWithUsername = state.formInfo?.username;
      state.formInfo = {};
    });
    builder.addCase(getCustomerInfo.fulfilled, (state, { payload }) => {
      const accountResponse = payload.data;
      state.user = {
        ...state.user,
        ...accountResponse,
        accountName: accountResponse.accountName,
        accountNumber: accountResponse.accountNumber,
        bank: accountResponse.bank,
      };
    });
    builder.addCase(generateBusinessAccount.fulfilled, (state, { payload }) => {
      state.user = {
        ...state.user,
        ...payload,
        businessRegNo: checkRCPrefix(payload.businessRegNo),
        hasBusinessVirtualAccount: true,
      };
    });
    builder.addCase(
      handleVirtualAccountRequest.fulfilled,
      (state, { payload }) => {
        state.user = {
          ...state.user,
          ...payload.data,
        };
      }
    );
    builder.addCase(updateUsername.fulfilled, (state, { payload }) => {
      state.user = {
        ...state.user,
        ...payload,
      };
      state.cachedUser = {
        ...state.cachedUser,
        username: state.username,
      };
    });
  },
});

export const {
  logout,
  setDomain,
  setUser,
  setBrowserRequestId,
  setFormInput,
  updateUserBvnStatus,
  toggleVisibility,
  toggleCommissionVisibility,
  updateBusiness,
  cacheUserInfo,
} = authSlice.actions;

export default authSlice.reducer;
