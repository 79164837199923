import React, { Fragment, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { authRoutes, authWithoutShell } from "./AuthRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import PageFallback from "../common/Fallback/PageFallback";
import { protectedRoutes as routes } from "./Routes";
import DashboardShell from "../layout/DashboardShell";
import AuthShell from "../layout/AuthShell";
import BlankShell from "../layout/BlankPageShell";
import NotFound from "../modules/404/Notfound";

/** function to generate routes */
const generateRoutes = ({ path, Component }, i) => {
  return (
    <Route
      key={i}
      path={path}
      element={
        <Suspense fallback={<PageFallback />}>
          <Component />
        </Suspense>
      }
    />
  );
};

const PageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<DashboardShell />}>
          <Route path="/" element={<Navigate to="/home" replace />} />
          {routes.map((route, i) => (
            <Fragment key={i}>{generateRoutes(route, i)}</Fragment>
          ))}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>

      <Route path="/" element={<AuthShell />}>
        {authRoutes.map((route, i) => (
          <Fragment key={i}>{generateRoutes(route, i)}</Fragment>
        ))}
      </Route>

      <Route path="/" element={<BlankShell />}>
        {authWithoutShell.map((route, i) => (
          <Fragment key={i}>{generateRoutes(route, i)}</Fragment>
        ))}
      </Route>
    </Routes>
  );
};

export default PageRoutes;
