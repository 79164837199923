import React from "react";
import Icon from "@ant-design/icons";

const OrderSvg = () => {
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-icon="setting"
    >
      <path d="M17.25 22.5C17.8465 22.4993 18.4185 22.2621 18.8403 21.8403C19.2621 21.4185 19.4993 20.8465 19.5 20.25V17.2148C19.5 17.0159 19.421 16.8251 19.2803 16.6845C19.1397 16.5438 18.9489 16.4648 18.75 16.4648C18.5511 16.4648 18.3603 16.5438 18.2197 16.6845C18.079 16.8251 18 17.0159 18 17.2148V20.25C17.9998 20.4489 17.9207 20.6395 17.7801 20.7801C17.6395 20.9207 17.4489 20.9998 17.25 21H3.75C3.55114 20.9998 3.36048 20.9207 3.21987 20.7801C3.07925 20.6395 3.00018 20.4489 3 20.25V3.75C3.00018 3.55114 3.07925 3.36048 3.21987 3.21987C3.36048 3.07925 3.55114 3.00018 3.75 3H17.25C17.4489 3.00018 17.6395 3.07925 17.7801 3.21987C17.9207 3.36048 17.9998 3.55114 18 3.75V6.063C18 6.26191 18.079 6.45268 18.2197 6.59333C18.3603 6.73398 18.5511 6.813 18.75 6.813C18.9489 6.813 19.1397 6.73398 19.2803 6.59333C19.421 6.45268 19.5 6.26191 19.5 6.063V3.75C19.4993 3.15346 19.2621 2.58155 18.8403 2.15973C18.4185 1.73792 17.8465 1.50066 17.25 1.5H3.75C3.15346 1.50066 2.58155 1.73792 2.15973 2.15973C1.73792 2.58155 1.50066 3.15346 1.5 3.75V20.25C1.50066 20.8465 1.73792 21.4185 2.15973 21.8403C2.58155 22.2621 3.15346 22.4993 3.75 22.5H17.25Z" />
      <path d="M5.25 14.25C5.05109 14.25 4.86032 14.329 4.71967 14.4697C4.57902 14.6103 4.5 14.8011 4.5 15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H8.25C8.44891 15.75 8.63968 15.671 8.78033 15.5303C8.92098 15.3897 9 15.1989 9 15C9 14.8011 8.92098 14.6103 8.78033 14.4697C8.63968 14.329 8.44891 14.25 8.25 14.25H5.25Z" />
      <path d="M19.9548 7.92369C19.8142 7.78309 19.6234 7.7041 19.4246 7.7041C19.2257 7.7041 19.0349 7.78309 18.8943 7.92369L11.4698 15.3482C11.4001 15.4179 11.3448 15.5005 11.3071 15.5915C11.2694 15.6825 11.25 15.7801 11.25 15.8786V17.2496H5.25C5.05109 17.2496 4.86032 17.3287 4.71967 17.4693C4.57902 17.61 4.5 17.8007 4.5 17.9996C4.5 18.1986 4.57902 18.3893 4.71967 18.53C4.86032 18.6706 5.05109 18.7496 5.25 18.7496H14.1215C14.3203 18.7496 14.5111 18.6706 14.6517 18.5299L22.0767 11.1053C22.2173 10.9647 22.2963 10.774 22.2963 10.5751C22.2963 10.3762 22.2173 10.1855 22.0767 10.0448L19.9548 7.92369ZM13.811 17.2496H12.75V16.1891L19.4246 9.51452L20.4855 10.5751L13.811 17.2496Z" />
      <path d="M11.25 7.875C11.25 7.20749 11.0521 6.55497 10.6812 5.99995C10.3104 5.44494 9.78326 5.01235 9.16656 4.75691C8.54986 4.50146 7.87126 4.43463 7.21657 4.56485C6.56189 4.69508 5.96052 5.01651 5.48852 5.48852C5.01651 5.96052 4.69508 6.56189 4.56485 7.21657C4.43463 7.87126 4.50146 8.54986 4.75691 9.16656C5.01235 9.78326 5.44494 10.3104 5.99995 10.6812C6.55497 11.0521 7.20749 11.25 7.875 11.25C8.7698 11.249 9.62767 10.8931 10.2604 10.2604C10.8931 9.62767 11.249 8.7698 11.25 7.875ZM7.875 9.75C7.50416 9.75 7.14165 9.64003 6.83331 9.43401C6.52497 9.22798 6.28464 8.93514 6.14273 8.59253C6.00081 8.24992 5.96368 7.87292 6.03603 7.50921C6.10838 7.14549 6.28695 6.8114 6.54918 6.54918C6.8114 6.28695 7.14549 6.10838 7.50921 6.03603C7.87292 5.96368 8.24992 6.00081 8.59253 6.14273C8.93514 6.28464 9.22798 6.52497 9.43401 6.83331C9.64003 7.14165 9.75 7.50416 9.75 7.875C9.74949 8.37212 9.55178 8.84874 9.20026 9.20026C8.84874 9.55178 8.37212 9.74949 7.875 9.75Z" />
    </svg>
  );
};

const Order = (props) => <Icon component={OrderSvg} {...props} />;

export default Order;
